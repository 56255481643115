import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ErrorDisplay } from '@oysterjs/ui/Form/text';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { FooterStyle, PageContainer, PageSection, TwoPaneContainer } from '@oysterjs/ui/Page/index';
import { CoverageItem, CoveragePageSection } from '@oysterjs/ui/CoverageItem';
import { CoveragePrice } from '@oysterjs/getoyster/pages/coverage';
import {
  Cadence,
  ProductType,
  PolicyType,
  ReferralLinkIntegration,
  MerchantIntegrationType,
  Policy,
  MerchantReferralSettings,
  Product,
  Insured,
  BikeProduct,
  Price,
  PublicMerchantRentalConfiguration
} from '@oysterjs/types';
import {
  getPolicyCoverageItems,
  getProductDisplayName,
  getProductFromPolicy
} from '@oysterjs/core/policy';
import {
  getAdditionalPolicyCoverageItems,
  getPolicyFromProduct,
  getProductDisplayImage
} from '@oysterjs/core/policy';
import { ButtonContainer, ButtonLink } from '@oysterjs/ui/Button';
import { IoArrowForward, IoBicycle, IoPerson } from 'react-icons/io5';
import { BikeQuickQuote } from './bike/quickquote';
import { MotorQuickQuote } from './motor/quickquote';
import { JewelryQuickQuote } from './jewelry/quickquote';
import { ElectronicsQuickQuote } from './electronics/quickquote';
import { createUrlDataComponent } from '@oysterjs/core/url';
import apm from '@oysterjs/core/apm';
import { getMerchantLink, getPublicMerchantRentalConfiguration } from '@oysterjs/core/api/merchant';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import { setMerchantAttribution } from '@oysterjs/core/analytics/attribution';
import { Spinner } from '@oysterjs/ui/Spinner';
import { TextSkeleton } from '@oysterjs/ui/Text/skeleton';
import { getTheme, isDark } from '@oysterjs/ui/Color/types';
import { sdkGetPolicy, sdkValidatePolicy } from '@oysterjs/core/api/sdk';
import { Banner } from '@oysterjs/ui/Banner';
import { HubspotChatContext } from '@oysterjs/core/hubspot';
import { RentalApplication } from './rental';

const TransitionContainer = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: 0.3s opacity ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: 0.3s opacity ease;
  }
`;

const PageBackground = styled.div<{ merchantBrandColor?: string; fitToContent?: boolean }>`
  background: linear-gradient(
    90deg,
    ${(props) => props.merchantBrandColor || '#0f172a'} 50%,
    white 50%
  );
  min-height: ${(props) => (props.fitToContent ? '0%' : '100%')};
  width: 100%;
  position: absolute;

  @media (max-width: 700px) {
    background: none;
  }
`;

const LogoContainer = styled.div<{ merchantBrandColor?: string }>`
  max-width: 50%;

  @media (max-width: 700px) {
    max-width: 100%;
    background: ${(props) => props.merchantBrandColor || '#0f172a'};
    transition: 0.15s ease-in-out background;
  }
`;

const LeftPaneContainer = styled.div<{ merchantBrandColor?: string; textColor?: string }>`
  color: white;

  .coverage-page-section--title,
  .coverage-page-section-coverage-item--description {
    color: ${(props) => props.textColor || 'rgba(255, 255, 255)'};
    opacity: 0.7;
  }

  .coverage-page-section-coverage-item--container {
    @media (min-width: 700px) {
      max-width: 100%;
    }
  }

  @media (max-width: 700px) {
    background: ${(props) => props.merchantBrandColor || '#0f172a'};
    transition: 0.15s ease-in-out background;
  }
`;

const ProductListContainer = styled.div<{ wrap?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  gap: 20px;

  ${(props) => (props.wrap ? 'flex-wrap: wrap' : '')}
`;

const ProductContainer = styled.div<{ selected?: boolean }>`
  border-radius: 15px;
  padding: 15px;
  border: ${(props) => (props.selected ? '2px solid #0ea5e9' : '2px dotted #f2f2f2')};
  transition: all 0.15s ease-in-out;
  cursor: pointer;
`;

const ProductImage = styled.img`
  display: block;
  width: 50px;
  height: 50px;
`;

const ProductLabel = styled.div`
  font-weight: 500;
  font-size: 0.8em;
  color: #333333;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
`;

const ProductListItem = (props: {
  productType: ProductType;
  selected?: boolean;
  externalID?: string;
}) => {
  let urlPath = `/${props.productType}`;
  if (props.externalID) {
    const channel = window.location.pathname.split('/')[1] || 'm';
    urlPath = `/${channel}/${props.externalID}/${props.productType}`;
  }

  return (
    <a href={urlPath} style={{ color: 'inherit', textDecoration: 'none' }}>
      <ProductContainer selected={props.selected}>
        <ProductImage src={getProductDisplayImage(props.productType)} height={200} width={200} />
      </ProductContainer>
      <ProductLabel>{getProductDisplayName(props.productType)}</ProductLabel>
    </a>
  );
};

interface LandingPageProps {
  externalID?: string;
  productType?: string;
  policyOffer?: Policy;
}

const disabledMerchants = ['gfs', 'garyfujiokasr'];

const useMerchant = (
  integrationType?: MerchantIntegrationType,
  integrationID?: string,
  externalID?: string,
  loadRentalConfiguration?: boolean
): [ReferralLinkIntegration | null, boolean, boolean, PublicMerchantRentalConfiguration | null] => {
  const [integration, setIntegration] = React.useState<ReferralLinkIntegration | null>(null);
  const [rentalConfig, setRentalConfig] = React.useState<PublicMerchantRentalConfiguration | null>(
    null
  );
  // Loading indicates that the merchant public handle is being looked up
  // and the application should wait until loading is complete to render.
  const [loading, setLoading] = React.useState(true);
  // bgLoading indicates that the merchant public handle is still being looked up
  // but the application should continue and render while the data is being loaded.
  const [bgLoading, setBGLoading] = React.useState(false);

  if (!integrationID && !externalID) {
    return [null, false, false, null];
  }

  if (disabledMerchants.includes(externalID || '')) {
    return [{ Disabled: true } as ReferralLinkIntegration, false, false, null];
  }

  React.useEffect(() => {
    if (!integrationID && (!externalID || !integrationType)) {
      return;
    }

    // Limit the time spent in the foreground looking up the merchant
    // integration to 3 seconds. This can then fallback to non-merchant
    // referral until the data is ready (if ever).
    const timeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
        setBGLoading(true);
      }
    }, 3000);

    setLoading(true);

    getMerchantLink(integrationType, externalID, integrationID)
      .then((integration) => {
        setMerchantAttribution(integration);
        setIntegration(integration);

        if (loadRentalConfiguration) {
          return getPublicMerchantRentalConfiguration(integration.IntegrationID)
            .then((r) => setRentalConfig(r.Configuration))
            .catch(() => setRentalConfig(null));
        }
      })
      .finally(() => {
        setLoading(false);
        setBGLoading(false);
        clearTimeout(timeout);
      });

    return () => clearTimeout(timeout);
  }, [integrationType, externalID, integrationID]);

  return [integration, loading, bgLoading, rentalConfig];
};

const getProductType = (productType: string | ProductType) => {
  switch (productType) {
    case ProductType.phone:
    case ProductType.electronics:
    case ProductType.offroad:
    case ProductType.motorcycle:
    case ProductType.bike:
    case ProductType.jewelry:
      return productType;
    default:
      return null;
  }
};

const getRewriteProductType = (productType: string) => {
  switch (productType) {
    case 'bikes':
      return ProductType.bike;
    default:
      return null;
  }
};

const getPolicyTypeLearnMoreLink = (
  policyType: PolicyType | null,
  productType?: ProductType,
  textColor?: string
) => {
  switch (policyType) {
    case PolicyType.bike:
    case PolicyType.markelBike:
      return (
        <a
          href="https://www.withoyster.com/library/understanding-bicycle-insurance"
          style={{ textDecoration: 'underline', color: textColor || '#0EA5E9' }}
          target="_blank"
        >
          Learn More.
        </a>
      );
    case PolicyType.minicoJewelry:
      return (
        <a
          href="https://www.withoyster.com/library/understanding-jewelry-insurance"
          style={{ textDecoration: 'underline', color: textColor || '#0EA5E9' }}
          target="_blank"
        >
          Learn More.
        </a>
      );
    case PolicyType.worthAveElectronics: {
      const articleURL =
        productType === ProductType.phone
          ? 'https://www.withoyster.com/library/understanding-phone-insurance'
          : 'https://www.withoyster.com/library/understanding-electronics-insurance';
      return (
        <a
          href={articleURL}
          style={{ textDecoration: 'underline', color: textColor || '#0EA5E9' }}
          target="_blank"
        >
          Learn More.
        </a>
      );
    }
    default:
      return (
        <a
          href="https://www.withoyster.com"
          style={{ textDecoration: 'underline', color: '#0EA5E9' }}
          target="_blank"
        >
          Learn More.
        </a>
      );
  }
};

const LandingPageQuickQuote = (props: {
  productType: ProductType;
  merchant: ReferralLinkIntegration | null;
  integrationType: MerchantIntegrationType;
  externalID?: string;
  brandColor?: string;
  textColor?: string;
}) => {
  const analytics = useAnalytics();

  const [_price, _setPrice] = React.useState(0);
  const [_error, _setError] = React.useState<Error>();
  const [price, setPrice] = React.useState(0);
  const [error, setError] = React.useState<Error>();
  const [data, setData] = React.useState<unknown>({});
  const [loading, setLoading] = React.useState(false);

  const set = (price: number, data: unknown, err?: Error) => {
    _setPrice(price);
    _setError(err);
    setData(data);
  };

  React.useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      if (!!_price && !_error) {
        analytics.event(GetOysterEventNames.GetAQuickQuote, {
          policy_type: props.productType
        });
      }

      setLoading(false);
      setPrice(_price);
      setError(_error);
    }, 500);
    return () => clearTimeout(timer);
  }, [_price, _error, data]);

  const urlParams = new URLSearchParams(window.location.search);
  new URLSearchParams(
    createUrlDataComponent({
      integrationID: props.merchant?.IntegrationID,
      appData: data
    })
  ).forEach((v, k) => urlParams.set(k, v));

  const nextUrl = `/app/${props.productType}?${urlParams.toString()}`;

  return (
    <>
      <PageSection noBorder>
        <h1>Get a quick quote.</h1>
        {[ProductType.phone, ProductType.electronics].includes(props.productType) && (
          <ProductListContainer>
            <ProductListItem
              productType={ProductType.phone}
              selected={props.productType === ProductType.phone}
              externalID={props.externalID}
            />
            <ProductListItem
              productType={ProductType.electronics}
              selected={props.productType === ProductType.electronics}
              externalID={props.externalID}
            />
          </ProductListContainer>
        )}

        {props.productType === ProductType.offroad && (
          <MotorQuickQuote onPriceUpdate={set} productType={props.productType} />
        )}
        {props.productType === ProductType.motorcycle && (
          <MotorQuickQuote onPriceUpdate={set} productType={props.productType} />
        )}
        {props.productType === ProductType.bike && <BikeQuickQuote onPriceUpdate={set} />}
        {props.productType === ProductType.phone && (
          <ElectronicsQuickQuote onPriceUpdate={set} productType={props.productType} />
        )}
        {props.productType === ProductType.electronics && (
          <ElectronicsQuickQuote onPriceUpdate={set} productType={props.productType} />
        )}
        {props.productType === ProductType.jewelry && <JewelryQuickQuote onPriceUpdate={set} />}
      </PageSection>
      <PageSection noBorder>
        {!!price && !error && (
          <CoveragePageSection title="Price Estimate">
            <CoveragePrice
              price={{ Amount: price, Currency: 'usd' }}
              cadence={Cadence.year}
              loading={loading}
            />
          </CoveragePageSection>
        )}
        <ButtonContainer>
          <ButtonLink
            primary
            icon={<IoArrowForward />}
            href={nextUrl}
            disabled={!!error}
            style={{ background: props.brandColor, color: props.textColor }}
            onClick={() =>
              apm().sendEvent('oyster.d2c.merchant_landing_page_flow_start', {
                product_type: props.productType,
                integration_id: props.merchant?.IntegrationID,
                external_id: props.externalID,
                path: window.location.pathname,
                query: window.location.search
              })
            }
          >
            Continue
          </ButtonLink>
        </ButtonContainer>
        {error && <ErrorDisplay>{error.message}</ErrorDisplay>}
      </PageSection>
    </>
  );
};

const LandingPageProductList = (props: { externalID?: string }) => (
  <PageSection noBorder>
    <h1>Choose an item</h1>
    <p>We'll show you what's covered and get you a free price estimate.</p>
    <ProductListContainer wrap>
      <ProductListItem productType={ProductType.bike} externalID={props.externalID} />
      <ProductListItem productType={ProductType.jewelry} externalID={props.externalID} />
      <ProductListItem productType={ProductType.phone} externalID={props.externalID} />
      <ProductListItem productType={ProductType.electronics} externalID={props.externalID} />
    </ProductListContainer>
  </PageSection>
);

const LandingPageProductDetails = (props: {
  productType: ProductType;
  merchant: ReferralLinkIntegration | null;
  textColor?: string;
  secondaryColor?: string;
  externalID?: string;
  merchantBGLoading?: boolean;
}) => {
  const policyType = getPolicyFromProduct(props.productType);

  return (
    <PageSection noBorder>
      <div style={{ color: props.textColor }}>
        <h1>Get protected with the best personal insurance.</h1>
        {(props.merchant || props.merchantBGLoading) && (
          <p>
            Oyster has partnered with
            <DisplayMerchantName
              merchant={props.merchant}
              merchantHandle={props.externalID || ''}
            />
            to provide the best coverage for your{' '}
            {getProductDisplayName(props.productType).toLowerCase()} with a seamless, modern
            experience end-to-end, from checkout to claim settlement.{' '}
            {getPolicyTypeLearnMoreLink(policyType, props.productType, props.secondaryColor)}
          </p>
        )}
        {!props.merchant && !props.merchantBGLoading && (
          <p>
            Oyster provides the best coverage for your{' '}
            {getProductDisplayName(props.productType).toLowerCase()} with a seamless, modern
            experience end-to-end, from checkout to claim settlement.{' '}
            {getPolicyTypeLearnMoreLink(policyType, props.productType, props.secondaryColor)}
          </p>
        )}
        <CoveragePageSection title="Base Coverage" color={props.textColor}>
          {getPolicyCoverageItems(policyType).map((item) => (
            <CoverageItem key={item.title} iconColor={props.secondaryColor} {...item} />
          ))}
        </CoveragePageSection>
        {getAdditionalPolicyCoverageItems(policyType).length > 0 && (
          <CoveragePageSection title="Additional Coverage">
            {getAdditionalPolicyCoverageItems(policyType).map((item) => (
              <CoverageItem key={item.title} iconColor={props.secondaryColor} {...item} />
            ))}
          </CoveragePageSection>
        )}
      </div>
    </PageSection>
  );
};

const LandingPageRentalDetails = (props: {
  merchant: ReferralLinkIntegration;
  textColor?: string;
  secondaryColor?: string;
  externalID?: string;
  merchantBGLoading?: boolean;
}) => {
  return (
    <PageSection noBorder>
      <div style={{ color: props.textColor || 'white' }}>
        <h1 style={{ marginTop: 0 }}>Protect your rental from theft and damage.</h1>
        <p>
          <DisplayMerchantName merchant={props.merchant} merchantHandle={props.externalID || ''} />{' '}
          has partnered with Oyster to provide comprehensive damage and theft protection for your
          rental. Now, you can ride worry-free with protection from some of the most common rental
          risks. Get started below.
        </p>
        <CoveragePageSection title="Coverage" color={props.textColor}>
          <CoverageItem
            title="Accidental Damage"
            description="Damage caused by accidents, crashes, and collisions, while using the equipment in its intended manner, is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Theft"
            description="Stolen equipment, even when unattended and secured, is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Catastrophic Damage"
            description="Damage caused by catastrophes like fire, hurricane, tornado, and hail is covered."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Accessories"
            description="Additional accessories, like bike locks, helmets, and flashlights are covered with your rental equipment."
            iconColor={props.secondaryColor}
          />
        </CoveragePageSection>
      </div>
    </PageSection>
  );
};

const LandingPageUnifiedWaiverDetails = (props: {
  merchant: ReferralLinkIntegration;
  textColor?: string;
  secondaryColor?: string;
}) => {
  return (
    <PageSection noBorder>
      <div style={{ color: props.textColor || 'white' }}>
        <h1 style={{ marginTop: 0 }}>Get started with your rental.</h1>
        <p>
          Before you start riding, complete the rental agreement for{' '}
          <DisplayMerchantName merchant={props.merchant} merchantHandle="" /> by filling out the
          form details, reading the agreement documents, and providing your signature
          electronically.
        </p>
        <CoveragePageSection title="Agreement Overview" color={props.textColor}>
          <CoverageItem
            title="Rental Responsibility"
            description="You agree to take responsibility for the rented equipment and return it in its original condition."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Liability Release"
            description={`You agree to release ${props.merchant.MerchantName} from any liability resulting from this rental.`}
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Restrictions on Use"
            description="You agree to only use this equipment according to the specified terms."
            iconColor={props.secondaryColor}
          />
          <CoverageItem
            title="Damage and Theft Waiver"
            description="You have the option to waive responsibility for theft and damage for an additional fee (terms apply)."
            iconColor={props.secondaryColor}
          />
        </CoveragePageSection>
      </div>
    </PageSection>
  );
};

const LandingPageIntroduction = (props: {
  merchant: ReferralLinkIntegration | null;
  externalID?: string;
  merchantBGLoading?: boolean;
}) => (
  <PageSection noBorder>
    <h1>Get protected with the best personal insurance.</h1>
    {(props.merchant || props.merchantBGLoading) && (
      <p>
        Oyster has partnered with
        <DisplayMerchantName merchant={props.merchant} merchantHandle={props.externalID || ''} />
        to provide the best coverage for your personal items with a seamless, modern experience
        end-to-end, from checkout to claim settlement. {getPolicyTypeLearnMoreLink(null)}
      </p>
    )}
    {!props.merchant && !props.merchantBGLoading && (
      <p>
        Oyster provides the best coverage for your personal property with a seamless, modern
        experience end-to-end, from checkout to claim settlement. {getPolicyTypeLearnMoreLink(null)}
      </p>
    )}
    <p>To start, choose an item.</p>
  </PageSection>
);

const InsuredItemsList = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
`;

const InsuredItemContainer = styled.div`
  border-radius: 16px;
  padding: 16px;
  border: 2px dotted #eaeaea;
  transition: all 0.15s ease-in-out;
  display: flex;
  gap: 16px;
  flex: 1 0 0;
`;

const InsuredItemDetails = styled.div`
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InsuredItemTitle = styled.div`
  gap: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const InsuredItemContent = styled.div`
  font-size: 0.9em;
  color: #666666;
`;

const InsuredItem = (props: { product: Product }) => {
  const getDetails = (): { key: string; value: string }[] => {
    switch (props.product.Type) {
      case ProductType.bike: {
        const bike = props.product.Details as BikeProduct;
        return [
          { key: 'Serial Number', value: bike.FrameSerialNumber || 'N/A' },
          { key: 'Model Year', value: bike.ModelYear || 'N/A' }
        ];
      }

      default:
        return [];
    }
  };

  return (
    <InsuredItemContainer>
      <img src={getProductDisplayImage(props.product.Type)} width={44} />
      <InsuredItemDetails>
        <InsuredItemTitle>
          <div>{props.product.Name}</div>
          <div>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: props.product.Price.Currency || 'usd',
              minimumFractionDigits: Number.isInteger(props.product.Price.Amount) ? 0 : 2,
              maximumFractionDigits: 2
            }).format(props.product.Price.Amount)}
          </div>
        </InsuredItemTitle>
        <InsuredItemContent>
          {getDetails().map(({ key, value }) => (
            <div key={key}>
              {key}: {value}
            </div>
          ))}
        </InsuredItemContent>
      </InsuredItemDetails>
    </InsuredItemContainer>
  );
};

export const RentalDescription = (props: {
  itemName: string;
  serialNumber: string;
  accessories: string[];
  startDate: Date;
  endDate: Date;
}) => {
  const formattedRentalDate = () => {
    const f = new Intl.DateTimeFormat(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    });

    return `${f.format(props.startDate)} - ${f.format(props.endDate)}`;
  };

  return (
    <InsuredItemContainer>
      <div>
        <IoBicycle style={{ fontSize: '2.5em', color: '#637D8C' }} />
      </div>
      <InsuredItemDetails>
        <InsuredItemTitle>
          <div>{props.itemName}</div>
        </InsuredItemTitle>
        <InsuredItemContent>
          <div>{props.serialNumber}</div>
          {props.accessories.length > 0 && <div>{props.accessories.join(', ')}</div>}
          <div>{formattedRentalDate()}</div>
        </InsuredItemContent>
      </InsuredItemDetails>
    </InsuredItemContainer>
  );
};

export const InsuredDescription = (props: { insured: Insured }) => (
  <InsuredItemContainer>
    <div>
      <IoPerson style={{ fontSize: '2.5em', color: '#637D8C' }} />
    </div>
    <InsuredItemDetails>
      <InsuredItemTitle>
        <div>
          {props.insured.FirstName} {props.insured.LastName}
        </div>
      </InsuredItemTitle>
      <InsuredItemContent>
        <div>{props.insured.Email}</div>
        {props.insured.AddressCity &&
          props.insured.AddressState &&
          props.insured.AddressZipCode && (
            <div>
              {props.insured.AddressCity.trim()}, {props.insured.AddressState.trim()}{' '}
              {props.insured.AddressZipCode.trim()}
            </div>
          )}
        {!props.insured.AddressCity && props.insured.Phone && <div>{props.insured.Phone}</div>}
      </InsuredItemContent>
    </InsuredItemDetails>
  </InsuredItemContainer>
);

const getContinueUrl = (policy: Policy) => {
  switch (policy.Type) {
    case PolicyType.markelBike:
      return `/app/bike/${policy.ID}`;
    case PolicyType.minicoJewelry:
    case PolicyType.chubbJewelry:
      return `/app/jewelry/${policy.ID}`;
    default:
      return '';
  }
};

const LandingPagePolicyPane = (props: { policy: Policy }) => {
  const defaultPrice = { Amount: 100, Currency: 'usd' };
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState<Price>();

  React.useEffect(() => {
    setLoading(true);
    sdkValidatePolicy(props.policy.ID)
      .then((policy) => setPrice(policy.Price || defaultPrice))
      .catch(() => setPrice(defaultPrice))
      .finally(() => setLoading(false));
  }, [props.policy]);

  return (
    <PageSection noBorder>
      <h1>Your personalized offer</h1>
      <Banner
        title="You may qualify for a better price"
        description="This quote is based on limited information. Complete your application to see exactly how much coverage would cost. There's no obligation and it only takes a minute!"
      />
      <CoveragePageSection title="Covered items">
        <InsuredItemsList>
          {props.policy.InsuredItems.map((item) => (
            <InsuredItem key={item.ID || item.SKU} product={item} />
          ))}
        </InsuredItemsList>
      </CoveragePageSection>
      <CoveragePageSection title="Who's covered">
        <InsuredDescription insured={props.policy.Underwriting.Insured} />
      </CoveragePageSection>
      <CoveragePageSection title="Your estimated premium">
        {!price && loading && <Spinner size={48} color="#cccccc" />}
        {price && (
          <CoveragePrice
            price={price}
            displayCadence={Cadence.year}
            cadence={props.policy.PaymentCadence}
          />
        )}
      </CoveragePageSection>
      <ButtonContainer>
        <ButtonLink primary icon={<IoArrowForward />} href={getContinueUrl(props.policy)}>
          Complete Application
        </ButtonLink>
      </ButtonContainer>
    </PageSection>
  );
};

const LandingPage: React.FunctionComponent<React.PropsWithChildren<LandingPageProps>> = (props) => {
  const hubspot = React.useContext(HubspotChatContext);
  const history = useHistory();

  let productType: ProductType | null = null;
  if (props.productType) {
    const rewriteProductType = getRewriteProductType(props.productType);

    if (rewriteProductType && props.externalID) {
      const path = window.location.pathname.split('/');
      path[path.length - 1] = rewriteProductType;
      history.replace(path.join('/'));
      return null;
    }

    productType = getProductType(props.productType);
    if (!productType) {
      const path = window.location.pathname.split('/');
      path[path.length - 1] = '';
      history.replace(path.join('/'));
      return null;
    }
  }

  let integrationType = MerchantIntegrationType.qr_code;
  let integrationID: string | undefined;
  switch (window.location.pathname.split('/')[1]) {
    case 'm':
      integrationType = MerchantIntegrationType.referral_link;
      break;
    case 'emp':
      integrationType = MerchantIntegrationType.embeddable_marketing_page;
      break;
    case 'q':
      integrationType = MerchantIntegrationType.qr_code;
      break;
    case 'offer':
      integrationID = (
        props.policyOffer?.ReferralChannelInfo?.Settings as MerchantReferralSettings | undefined
      )?.IntegrationID;
  }

  const [merchant, merchantLoading, merchantBGLoading] = useMerchant(
    integrationType,
    integrationID,
    props.externalID
  );

  React.useEffect(() => {
    // Wait for merchant integration to load, then send the event.
    // This will be `false` if there is no merchant to load in the first place.
    if (merchantLoading) {
      return;
    }

    apm().sendEvent('oyster.d2c.merchant_landing_page', {
      product_type: props.productType,
      integration_id: merchant?.IntegrationID,
      external_id: props.externalID,
      path: window.location.pathname,
      query: window.location.search,
      disabled: merchant?.Disabled
    });
  }, [merchantLoading, window.location.href]);

  React.useEffect(() => {
    if (!merchant || !props.policyOffer) {
      return;
    }

    hubspot?.identify(
      props.policyOffer.Underwriting?.Insured?.Email || '',
      props.policyOffer.Underwriting?.Insured?.FirstName || '',
      props.policyOffer.Underwriting?.Insured?.LastName || ''
    );
  }, [merchant, props.policyOffer]);

  if (merchant?.Disabled) {
    history.replace('/404');
  }

  const color = merchant?.Settings.BrandColor;
  const useDark = color && isDark(color);
  const tonalOffset = useDark ? 0.3 : 0.5;
  const theme = color ? getTheme(color, tonalOffset) : null;
  const brandColor = theme?.palette.primary.main || '';
  const textColor = theme?.palette.primary.contrastText || '';
  const secondaryColor =
    (theme && (useDark ? theme.palette.primary.dark : theme.palette.primary.light)) || '';

  return (
    <TransitionGroup>
      {merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <PageContainer footerStyle={FooterStyle.none}>
              <PageSection noBorder centered>
                <Spinner color="#333333" />
              </PageSection>
            </PageContainer>
          </TransitionContainer>
        </CSSTransition>
      )}
      {!merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <PageBackground merchantBrandColor={brandColor}>
              <PageContainer footerTextColor={textColor}>
                <LogoContainer merchantBrandColor={brandColor}>
                  <PageSection noBorder>
                    <a href="https://withoyster.com">
                      <OysterLogo color={secondaryColor} light />
                    </a>
                  </PageSection>
                </LogoContainer>
                <TwoPaneContainer
                  leftPaneWidth={50}
                  rightPane={
                    props.policyOffer ? (
                      <LandingPagePolicyPane policy={props.policyOffer} />
                    ) : productType ? (
                      <LandingPageQuickQuote
                        productType={productType}
                        merchant={merchant}
                        integrationType={integrationType}
                        externalID={props.externalID}
                        brandColor={brandColor}
                        textColor={textColor}
                      />
                    ) : (
                      <LandingPageProductList externalID={props.externalID} />
                    )
                  }
                >
                  <LeftPaneContainer merchantBrandColor={brandColor} textColor={textColor}>
                    {productType ? (
                      <LandingPageProductDetails
                        productType={productType}
                        merchant={merchant}
                        externalID={props.externalID}
                        merchantBGLoading={merchantBGLoading}
                        textColor={textColor}
                        secondaryColor={secondaryColor}
                      />
                    ) : (
                      <LandingPageIntroduction
                        merchant={merchant}
                        externalID={props.externalID}
                        merchantBGLoading={merchantBGLoading}
                      />
                    )}
                  </LeftPaneContainer>
                </TwoPaneContainer>
              </PageContainer>
            </PageBackground>
          </TransitionContainer>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

const DisplayMerchantName = (props: {
  merchant: ReferralLinkIntegration | null | undefined;
  merchantHandle: string;
}) => {
  if (props.merchant) {
    return <b> {props.merchant.MerchantName} </b>;
  }

  // we remove non-alphanumeric and lower-case characters, so we
  // need to compensate in the width estimate. assume one additional
  // character for every 4 characters.
  const padding = ' '.repeat(Math.ceil(props.merchantHandle.length / 4));
  // insert the padding somewhere in the middle of the sizing text so that
  // they don't get automatically stripped from either end.
  const sizingText = props.merchantHandle[0] + padding + props.merchantHandle.substring(1);

  // The text in this component is solely for a size estimate, it is not displayed.
  return <TextSkeleton>{sizingText}</TextSkeleton>;
};

export const PolicyOfferLandingPage = (props: { policyId: string }) => {
  const [loading, setLoading] = React.useState(true);
  const [policy, setPolicy] = React.useState<Policy>();
  const [error, setError] = React.useState();

  React.useEffect(() => {
    setLoading(true);
    sdkGetPolicy(props.policyId)
      .then((policy) => setPolicy(policy))
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false));
  }, [props.policyId]);

  if (loading) {
    return (
      <PageContainer footerStyle={FooterStyle.none}>
        <PageSection noBorder centered>
          <Spinner color="#333333" />
        </PageSection>
      </PageContainer>
    );
  }

  if (error) {
    throw error;
  }

  let productType: string | undefined;
  if (policy) {
    productType = getProductFromPolicy(policy.Type)?.toString();
  }

  return <LandingPage policyOffer={policy} productType={productType} />;
};

const RentalLandingIntro = (props: {
  merchant: ReferralLinkIntegration;
  children: (textColor: string, secondaryColor: string) => JSX.Element;
}) => {
  const color = props.merchant.Settings.BrandColor;
  const useDark = color && isDark(color);
  const tonalOffset = useDark ? 0.3 : 0.5;
  const theme = color ? getTheme(color, tonalOffset) : null;
  const brandColor = theme?.palette.primary.main || '';
  const textColor = theme?.palette.primary.contrastText || '';
  const secondaryColor =
    (theme && (useDark ? theme.palette.primary.dark : theme.palette.primary.light)) || '';

  return (
    <PageBackground
      merchantBrandColor={brandColor}
      style={{
        minHeight: '0%',
        background: brandColor || '#0f172a',
        position: 'relative'
      }}
    >
      <PageContainer footerTextColor={textColor} footerStyle={FooterStyle.none} width={800}>
        <LogoContainer merchantBrandColor={brandColor}>
          <PageSection noBorder>
            <a href="https://withoyster.com">
              <OysterLogo color={secondaryColor} light />
            </a>
          </PageSection>
        </LogoContainer>
        {props.children(textColor, secondaryColor)}
      </PageContainer>
    </PageBackground>
  );
};

export const RentalLandingPage = (props: {
  externalId?: string;
  bookingId?: string;
  integrationType: MerchantIntegrationType;
}) => {
  if (!props.externalId) {
    return null;
  }

  const [merchant, merchantFGLoading, merchantBGLoading, rentalConfig] = useMerchant(
    props.integrationType,
    undefined,
    props.externalId,
    true
  );

  const merchantLoading = merchantFGLoading || merchantBGLoading;

  React.useEffect(() => {
    // Wait for merchant integration to load, then send the event.
    // This will be `false` if there is no merchant to load in the first place.
    if (merchantLoading) {
      return;
    }

    apm().sendEvent('oyster.d2c.rental_landing_page', {
      integration_id: merchant?.IntegrationID,
      external_id: props.externalId,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [merchantLoading, window.location.href]);

  // Resolve external ID into a merchant and merchant rental configuration
  // If no merchant or no rental configuration, throw error

  if (!merchantLoading && !rentalConfig) {
    throw new Error('');
  }

  return (
    <TransitionGroup>
      {merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <PageContainer footerStyle={FooterStyle.none}>
              <PageSection noBorder centered>
                <Spinner color="#333333" />
              </PageSection>
            </PageContainer>
          </TransitionContainer>
        </CSSTransition>
      )}
      {rentalConfig && merchant && !merchantLoading && (
        <CSSTransition component={null} unmountOnExit timeout={500} classNames="fade">
          <TransitionContainer>
            <>
              <RentalLandingIntro merchant={merchant}>
                {(textColor, secondaryColor) =>
                  rentalConfig?.UnifiedWaiverFlowEnabled ? (
                    <LandingPageUnifiedWaiverDetails
                      merchant={merchant}
                      textColor={textColor}
                      secondaryColor={secondaryColor}
                    />
                  ) : (
                    <LandingPageRentalDetails
                      merchant={merchant}
                      textColor={textColor}
                      secondaryColor={secondaryColor}
                    />
                  )
                }
              </RentalLandingIntro>
              <PageContainer width={800}>
                <PageSection>
                  <RentalApplication
                    integrationId={merchant.IntegrationID}
                    bookingId={props.bookingId}
                    rentalConfig={rentalConfig}
                  />
                </PageSection>
              </PageContainer>
            </>
          </TransitionContainer>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

export default (props: React.PropsWithChildren<LandingPageProps>) => <LandingPage {...props} />;
