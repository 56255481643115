import * as React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import { Progress } from '@oysterjs/ui/Form/progress';

import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  ValidationError,
  JewelryCriteria,
  JewelryUnderwritingFlag,
  JewelryStorageLocationFlag,
  Product,
  JewelryProductValidationKeys,
  AttachmentFile,
  DefaultFileRoles,
  PolicyType
} from '@oysterjs/types';
import { TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import {
  IoArrowForward,
  IoBagCheckOutline,
  IoPricetagsOutline,
  IoDocumentTextOutline,
  IoHomeOutline,
  IoPersonOutline,
  IoArrowBack,
  IoAddOutline,
  IoCloseOutline
} from 'react-icons/io5';
import {
  sdkCreatePolicy,
  sdkCreateTemporaryAttachments,
  sdkGetPaymentSession,
  sdkUpdatePolicy,
  sdkValidatePolicy
} from '@oysterjs/core/api/sdk';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Radio } from '@oysterjs/ui/Form/radio';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ExistingUserWrapper, GetPolicyWrapper, Page, PageProps, PageWrapper } from '../../page';
import { getPaymentPage } from '../../payment';
import { getCoveragePage } from '../../coverage';
import { JewelryProduct, Policy } from '@oysterjs/types';
import { useUrlData } from '@oysterjs/core/url';
import apm from '@oysterjs/core/apm';
import { CollectJewelryInfo, JewelryFormData } from '../collect';
import { ProductInfoPage } from '../../product';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { userGetPaymentSession } from '@oysterjs/core/api/user';
import { OysterLogo } from '@oysterjs/ui/Logo';

const CollectJewelryInfoPage: React.FunctionComponent<
  React.PropsWithChildren<{ policy?: Policy }>
> = (props) => {
  const urlData = useUrlData<JewelryFormData>();
  const history = useHistory();
  const referralChannel = useReferralChannel();

  const onSubmit = (product: Product) => {
    const uploadAttachmentHandler = () => {
      const details = product.Details as JewelryProduct;
      const newFiles = details._AppraisalFiles;
      if (newFiles && newFiles.length > 0) {
        return sdkCreateTemporaryAttachments(
          DefaultFileRoles.purchaseReceipt,
          newFiles,
          details.AppraisalFileIDs
        );
      }
      return Promise.resolve(new Array<AttachmentFile>());
    };

    return uploadAttachmentHandler().then((filesMetadata) => {
      const updatedDetails = {
        ...product.Details,
        _AppraisalFiles: undefined,
        AppraisalFileIDs: filesMetadata
          .map((metadata) => metadata.ID)
          .concat((product.Details as JewelryProduct).AppraisalFileIDs || [])
      };

      if (props.policy) {
        return sdkUpdatePolicy(
          {
            ...props.policy,
            InsuredItems: [
              {
                ...product,
                Details: updatedDetails
              }
            ]
          },
          true
        );
      }
      return sdkCreatePolicy(
        [
          {
            ...product,
            Details: updatedDetails
          }
        ],
        {
          integrationID: urlData.integrationID,
          referralChannel
        }
      );
    });
  };

  const onContinue = (policy?: Policy) => {
    if (!props.policy) {
      history.replace(`/app/jewelry/${policy?.ID}`);
    } else if (
      props.policy.Type == PolicyType.chubbJewelry ||
      policy?.Type == PolicyType.chubbJewelry
    ) {
      history.push(`/app/jewelry/v2/${props.policy?.ID || policy?.ID}/criteria`);
    } else {
      history.push(`/app/jewelry/${props.policy?.ID || policy?.ID}/location`);
    }
  };

  const price = props.policy?.InsuredItems?.[0].Price?.Amount;

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as JewelryProduct;
  }

  const initialFormData = props.policy
    ? {
        Price: price?.toString() || '',
        Name: props.policy.InsuredItems[0].Name,
        Description: props.policy.InsuredItems[0].Description,
        Details: props.policy.InsuredItems[0].Details as JewelryProduct
      }
    : urlData?.appData
    ? urlData.appData
    : { Price: '', Details: {} as JewelryProduct };

  return (
    <ProductInfoPage
      title="Tell us about your jewelry"
      description="We need a bit of information about your jewelry before we can show you coverage options."
      initialFormData={initialFormData}
      hasError={pages[0].hasError}
      onSubmit={onSubmit}
      onContinue={onContinue}
      component={CollectJewelryInfo}
    />
  );
};

export const CriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>A bit about you</h1>
    <p>Tell us a little bit about yourself.</p>
    <JewelryCriteriaPage {...props} />
  </PageSection>
);

const JewelryCriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [inputChanged, setInputChanged] = React.useState(false);
  const criteria = props.policy.Underwriting.Criteria as JewelryCriteria;

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [props.validationError]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeInsured = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          [k]: v
        }
      }
    });
  };

  const onChangePriorLoss = <T,>(lossIdentifier: string, k: string, v: T) => {
    setInputChanged(true);
    const target = criteria.PriorLosses.find(
      (priorLoss) => priorLoss.lossIdentifier === lossIdentifier
    );

    if (target) {
      const index = criteria.PriorLosses.indexOf(target);
      const updatedLoss = {
        ...target,
        [k]: v
      };
      const updatedLosses = new Array(...criteria.PriorLosses);
      updatedLosses[index] = updatedLoss;

      props.onUpdate({
        ...props.policy,
        Underwriting: {
          ...props.policy.Underwriting,
          Criteria: {
            ...criteria,
            PriorLosses: updatedLosses
          }
        }
      });
    }
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader title="Personal information" />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'FirstName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('FirstName', value);
            }}
            autoComplete="given-name"
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'LastName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('LastName', value);
            }}
            autoComplete="family-name"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'Email' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Email', value);
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'Phone' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Phone', value);
            }}
            inputMode="tel"
            autoComplete="tel"
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn title="Occupation">
          <TextInput
            style={{ maxWidth: 'calc(50% - 8px)' }}
            error={
              !inputChanged &&
              validationError?.SubField === 'Occupation' &&
              validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Occupation}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Occupation', value);
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '0px', paddingTop: '16px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('HasAdditionalInsured', !criteria.HasAdditionalInsured);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label={'AdditionalInsuredCheckbox'}
              checked={criteria.HasAdditionalInsured}
              onChange={() => {
                onChange('HasAdditionalInsured', !criteria.HasAdditionalInsured);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>Add another person to this policy</RequirementTitle>
            <RequirementDescription>
              This ensures that coverage extends to this person in the event of a loss. For example,
              if this is a wedding or engagement ring, you'd likely want to add your spouse or the
              ring-wearer if that person is not you.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {criteria.HasAdditionalInsured && (
        <>
          <FormRowHeader title="Additional insured information" />
          <FormRow>
            <FormColumn title="First name">
              <TextInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'AdditionalInsuredFirstName' &&
                  validationError?.Message
                }
                value={criteria.AdditionalInsuredFirstName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('AdditionalInsuredFirstName', value);
                }}
                autoComplete="given-name"
              />
            </FormColumn>
            <FormColumn title="Last name">
              <TextInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'AdditionalInsuredLastName' &&
                  validationError?.Message
                }
                value={criteria.AdditionalInsuredLastName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('AdditionalInsuredLastName', value);
                }}
                autoComplete="family-name"
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '0px', paddingTop: '16px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('IsCurrentlyInsured', !criteria.IsCurrentlyInsured);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label={'IsCurrentlyInsuredCheckbox'}
              checked={criteria.IsCurrentlyInsured}
              onChange={() => {
                onChange('IsCurrentlyInsured', !criteria.IsCurrentlyInsured);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>Jewelry is currently insured</RequirementTitle>
            <RequirementDescription>
              Check this box if this jewelry item is already insured.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {criteria.IsCurrentlyInsured && (
        <>
          <FormRow>
            <FormColumn title="Current insurer name">
              <TextInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'CurrentInsurerName' &&
                  validationError?.Message
                }
                value={criteria.CurrentInsurerName}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('CurrentInsurerName', value);
                }}
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      <FormRowHeader
        title="Prior losses"
        description="Please describe any personal property losses or claims you've experienced in the past 5 years."
      />
      {criteria.PriorLosses.length > 0 &&
        criteria.PriorLosses.map((priorLoss, index) => {
          const dateOfLoss = new Date(priorLoss.dateOfLoss);
          const hasDateOfLoss = dateOfLoss.getTime() > 0;
          const key = priorLoss.lossIdentifier;
          const validationField = 'PriorLosses' + index;

          return (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '16px' }}
              key={key}
            >
              <FormRow breakMobile>
                <FormColumn title="Date of loss">
                  <DateOfBirthInput
                    onChange={(_, __, ___, dateObject) => {
                      onChangePriorLoss(priorLoss.lossIdentifier, 'dateOfLoss', dateObject);
                    }}
                    initialValue={
                      hasDateOfLoss
                        ? [
                            dateOfLoss.getUTCDate().toString(),
                            (dateOfLoss.getUTCMonth() + 1).toString(),
                            dateOfLoss.getUTCFullYear().toString()
                          ]
                        : undefined
                    }
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'DateOfLoss'
                        ? validationError?.Message
                        : undefined
                    }
                  />
                </FormColumn>
                <FormColumn title="Amount">
                  <TextInput
                    currency={true}
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'LossAmount'
                        ? validationError?.Message
                        : undefined
                    }
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      onChangePriorLoss(priorLoss.lossIdentifier, 'lossAmount', Number(value));
                    }}
                    value={priorLoss.lossAmount}
                    inputMode="decimal"
                  />
                </FormColumn>
              </FormRow>
              <FormRow>
                <FormColumn title="Description" label="Loss Description">
                  <TextAreaInput
                    error={
                      !inputChanged &&
                      validationError?.Field === validationField &&
                      validationError?.SubField === 'Description'
                        ? validationError?.Message
                        : undefined
                    }
                    value={priorLoss.description}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      onChangePriorLoss(priorLoss.lossIdentifier, 'description', value);
                    }}
                    style={{ resize: 'none', maxWidth: '100%' }}
                    rows={4}
                  />
                </FormColumn>
              </FormRow>
              <Button
                leftIcon={<IoCloseOutline />}
                onClick={(e) => {
                  e.preventDefault();
                  onChange('PriorLosses', [
                    ...criteria.PriorLosses.filter(
                      (s) => s.lossIdentifier !== priorLoss.lossIdentifier
                    )
                  ]);
                }}
              >
                Remove this loss
              </Button>
            </div>
          );
        })}
      {criteria.PriorLosses.length < 20 && (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
          <Button
            leftIcon={<IoAddOutline />}
            onClick={(e) => {
              e.preventDefault();
              onChange('PriorLosses', [
                ...criteria.PriorLosses,
                { lossIdentifier: 'loss_' + new Date().getTime().toString() } // Generate temporary ID
              ]);
            }}
          >
            Add a loss
          </Button>
        </div>
      )}
      <FormRowHeader
        title="Select any statements that apply"
        description="These questions help us further determine whether you're eligible for Oyster insurance."
      />
      <FormRow
        error={
          !inputChanged && validationError?.Field === 'UnderwritingFlags'
            ? validationError?.Message
            : undefined
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {[
            {
              value: JewelryUnderwritingFlag.default,
              name: 'Past default',
              description:
                'Has your jewelry insurance previously been canceled, declined, refused, or non-renewed in the past 5 years?'
            },
            {
              value: JewelryUnderwritingFlag.exhibition,
              name: 'Exhibition',
              description: 'Do you show or loan any of your jewelry at any exhibitions or events?'
            },
            {
              value: JewelryUnderwritingFlag.bankruptcy,
              name: 'Bankruptcy',
              description: 'Have you filed for bankruptcy in the past 5 years?'
            },
            {
              value: JewelryUnderwritingFlag.judgment,
              name: 'Past judgments',
              description:
                'Have you been subject to any liens, judgments or repossessions in the past 5 years?'
            },
            {
              value: JewelryUnderwritingFlag.conviction,
              name: 'Prior convictions',
              description: 'Have you ever been convicted of a crime, including misdemeanors?'
            }
          ].map((option) => (
            <RequirementItemContainer
              style={{ padding: '0px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                const s = new Set(criteria.UnderwritingFlags);
                if (s.has(option.value)) {
                  s.delete(option.value);
                } else {
                  s.add(option.value);
                }
                onChange('UnderwritingFlags', [...s]);
              }}
              key={option.value}
            >
              <RequirementCheckboxContainer>
                <Checkbox
                  label={option.name}
                  checked={criteria.UnderwritingFlags.includes(option.value)}
                  onChange={() => {
                    const s = new Set(criteria.UnderwritingFlags);
                    if (s.has(option.value)) {
                      s.delete(option.value);
                    } else {
                      s.add(option.value);
                    }
                    onChange('UnderwritingFlags', [...s]);
                  }}
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>{option.name}</RequirementTitle>
                <RequirementDescription>{option.description}</RequirementDescription>
              </RequirementContentContainer>
            </RequirementItemContainer>
          ))}
        </div>
      </FormRow>
      {criteria.UnderwritingFlags.length > 0 && (
        <>
          <FormRowHeader
            title="Please tell us more"
            description="You indicated one of the above options. Please tell us a bit more about it."
          />
          <FormRow>
            <FormColumn label="Underwriting Justification">
              <TextAreaInput
                error={
                  !inputChanged &&
                  validationError?.Field === 'UnderwritingJustification' &&
                  validationError?.Message
                }
                value={criteria.UnderwritingJustification}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  onChange('UnderwritingJustification', value);
                }}
                style={{ resize: 'none', maxWidth: '100%' }}
                rows={4}
              />
            </FormColumn>
          </FormRow>
        </>
      )}
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

export const LocationPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>Jewelry storage</h1>
    <p>Tell us a little bit about where and how you'll store this jewelry.</p>
    <JewelryLocationPage {...props} />
  </PageSection>
);

const JewelryLocationPage = (props: PageProps) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const criteria = props.policy.Underwriting.Criteria as JewelryCriteria;

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeMulti = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  const onChangeMultiInsured = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader
        title="Storage address"
        description="Enter the address that this jewelry will be stored at. For gifts, please enter the recipient's address."
      />
      <AddressInputForm
        showSecondLine
        onChange={(address) => {
          onChangeMulti([
            ['StorageStreetAddress', address.streetAddress],
            ['StorageAptFloor', address.aptFloor],
            ['StorageAptUnit', address.aptUnit],
            ['StorageCity', address.city],
            ['StorageState', address.state],
            ['StorageZipCode', address.zipCode]
          ]);
        }}
        initialValue={{
          streetAddress: criteria.StorageStreetAddress,
          aptFloor: criteria.StorageAptFloor,
          aptUnit: criteria.StorageAptUnit,
          city: criteria.StorageCity,
          state: criteria.StorageState,
          zipCode: criteria.StorageZipCode
        }}
        validationError={{
          streetAddress:
            !inputChanged &&
            props.validationError?.Field === 'StorageStreetAddress' &&
            props.validationError?.Message,
          aptFloor:
            !inputChanged &&
            props.validationError?.Field === 'StorageAptFloor' &&
            props.validationError?.Message,
          aptUnit:
            !inputChanged &&
            props.validationError?.Field === 'StorageAptUnit' &&
            props.validationError?.Message,
          city:
            !inputChanged &&
            props.validationError?.Field === 'StorageCity' &&
            props.validationError?.Message,
          state:
            !inputChanged &&
            props.validationError?.Field === 'StorageState' &&
            props.validationError?.Message,
          zipCode:
            !inputChanged &&
            props.validationError?.Field === 'StorageZipCode' &&
            props.validationError?.Message
        }}
      />
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '16px 0px 0px 0px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('StorageIsResidential', !criteria.StorageIsResidential);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label="This is also my residential address"
              checked={criteria.StorageIsResidential}
              onChange={() => {
                onChange('StorageIsResidential', !criteria.StorageIsResidential);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>This is also my residential address</RequirementTitle>
            <RequirementDescription>
              Check this box if you also live at this address.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {!criteria.StorageIsResidential && (
        <>
          <FormRowHeader
            title="Residential address"
            description="Enter the address that you live at."
          />
          <AddressInputForm
            showSecondLine
            singleSecondLine
            onChange={(address) => {
              onChangeMultiInsured([
                ['AddressLine1', address.streetAddress],
                ['AddressLine2', address.aptFloor],
                ['AddressCity', address.city],
                ['AddressState', address.state],
                ['AddressZipCode', address.zipCode]
              ]);
            }}
            initialValue={{
              streetAddress: props.policy.Underwriting.Insured.AddressLine1,
              streetAddressLine2: props.policy.Underwriting.Insured.AddressLine2,
              city: props.policy.Underwriting.Insured.AddressCity,
              state: props.policy.Underwriting.Insured.AddressState,
              zipCode: props.policy.Underwriting.Insured.AddressZipCode
            }}
            validationError={{
              streetAddress:
                !inputChanged &&
                props.validationError?.SubField === 'AddressLine1' &&
                props.validationError?.Message,
              streetAddressLine2:
                !inputChanged &&
                props.validationError?.SubField === 'AddressLine2' &&
                props.validationError?.Message,
              city:
                !inputChanged &&
                props.validationError?.SubField === 'AddressCity' &&
                props.validationError?.Message,
              state:
                !inputChanged &&
                props.validationError?.SubField === 'AddressState' &&
                props.validationError?.Message,
              zipCode:
                !inputChanged &&
                props.validationError?.SubField === 'AddressZipCode' &&
                props.validationError?.Message
            }}
          />
        </>
      )}
      <FormRowHeader
        title="Construction type"
        description="What material is the jewelry storage location made of?"
      />
      <FormRow
        error={
          !inputChanged && props.validationError?.Field === 'ConstructionType'
            ? props.validationError?.Message
            : undefined
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {[
            {
              value: 'Frame',
              name: 'Wood Frame',
              description:
                'Wood, wood veneer, stucco, brick, veneer, or half stucco/half brick exterior'
            },
            {
              value: 'Masonry',
              name: 'Brick veneer, Masonry, or Fire Resistive',
              description:
                'Concrete, concrete tilt up, cinder block, all brick, all stone, or all metal/steel walls'
            },
            {
              value: 'Other',
              name: 'Other',
              description: 'Some other construction material'
            }
          ].map((option) => (
            <RequirementItemContainer
              style={{ padding: '0px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onChange('ConstructionType', option.value);
              }}
              key={option.value}
            >
              <RequirementCheckboxContainer>
                <Radio
                  label={option.name}
                  checked={criteria.ConstructionType === option.value}
                  onChange={() => {
                    onChange('ConstructionType', option.value);
                  }}
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>{option.name}</RequirementTitle>
                <RequirementDescription>{option.description}</RequirementDescription>
              </RequirementContentContainer>
            </RequirementItemContainer>
          ))}
        </div>
      </FormRow>
      <FormRowHeader
        title="Construction year"
        description="Approximately which year was the jewelry storage location constructed?"
      />
      <FormRow>
        <FormColumn>
          <TextInput
            value={criteria.ConstructionYear}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChange('ConstructionYear', value);
            }}
            style={{ maxWidth: '100px' }}
            error={
              !inputChanged &&
              props.validationError?.Field === 'ConstructionYear' &&
              props.validationError?.Message
            }
            inputMode="numeric"
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Select any statements that apply."
        description="Select the items below that most accurately describe the jewelry storage location."
      />
      <FormRow
        error={
          !inputChanged && props.validationError?.Field === 'StorageLocationFlags'
            ? props.validationError?.Message
            : undefined
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {[
            {
              value: JewelryStorageLocationFlag.has_doorman,
              name: '24-hour doorman',
              description: 'Your apartment or condo building has a doorman present 24/7.',
              hidden: criteria.StorageAptFloor === '' && criteria.StorageAptUnit === ''
            },
            {
              value: JewelryStorageLocationFlag.deadbolts,
              name: 'Deadbolt locks',
              description: (
                <>
                  Double check that there are deadbolt locks on all exterior doors.{' '}
                  <b>No deadbolt locks may result in a longer application process.</b>
                </>
              )
            },
            {
              value: JewelryStorageLocationFlag.central_station_fire,
              name: 'Station-monitored fire alarm',
              description: 'This location has a fire alarm that is monitored by a fire station.'
            },
            {
              value: JewelryStorageLocationFlag.burglar_alarm,
              name: 'Burglar alarm',
              description: 'This location has a burglar alarm.'
            },
            {
              value: JewelryStorageLocationFlag.central_station_burglar_alarm,
              name: 'Professionally monitored',
              description:
                'The burglar alarm at this location is monitored by a professional service.',
              hidden: !criteria.StorageLocationFlags.includes(
                JewelryStorageLocationFlag.burglar_alarm
              ),
              indented: true
            },
            {
              value: JewelryStorageLocationFlag.near_fire_hydrant,
              name: 'Nearby fire hydrant',
              description: 'This location is within 1,000 feet of a fire hydrant.'
            },
            {
              value: JewelryStorageLocationFlag.below_ground,
              name: 'Storage below ground',
              description: 'The jewelry will be stored below-ground at this location.'
            },
            {
              value: JewelryStorageLocationFlag.off_the_ground,
              name: 'Jewelry is at least 12 inches above the floor',
              description: 'The below-ground jewelry is stored at least 12 inches above the floor.',
              hidden: !criteria.StorageLocationFlags.includes(
                JewelryStorageLocationFlag.below_ground
              ),
              indented: true
            },
            {
              value: JewelryStorageLocationFlag.flood_zone,
              name: 'NFIP flood zone',
              description:
                'This location is in a flood zone as designated by the National Flood Insurance Program.'
            }
          ]
            .filter((option) => !option.hidden)
            .map((option) => (
              <RequirementItemContainer
                style={{
                  padding: '0px',
                  cursor: 'pointer',
                  display: 'border-box',
                  paddingLeft: option.indented ? '16px' : undefined
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  const s = new Set(criteria.StorageLocationFlags);
                  if (s.has(option.value)) {
                    s.delete(option.value);
                  } else {
                    s.add(option.value);
                  }
                  onChange('StorageLocationFlags', [...s]);
                }}
                key={option.value}
              >
                <RequirementCheckboxContainer>
                  <Checkbox
                    label={option.name}
                    checked={criteria.StorageLocationFlags.includes(option.value)}
                    onChange={() => {
                      const s = new Set(criteria.StorageLocationFlags);
                      if (s.has(option.value)) {
                        s.delete(option.value);
                      } else {
                        s.add(option.value);
                      }
                      onChange('StorageLocationFlags', [...s]);
                    }}
                  />
                </RequirementCheckboxContainer>
                <RequirementContentContainer>
                  <RequirementTitle>{option.name}</RequirementTitle>
                  <RequirementDescription>{option.description}</RequirementDescription>
                </RequirementContentContainer>
              </RequirementItemContainer>
            ))}
        </div>
      </FormRow>
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/jewelry/:id?',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <CollectJewelryInfoPage policy={policy} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      JewelryProductValidationKeys.indexOf(validationError.Field as keyof JewelryProduct) > -1 ||
      validationError.Field === 'Attachment',
    icon: <IoPricetagsOutline />
  },
  {
    path: '/app/jewelry/:id/location',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper policy={policy} pages={pages} component={LocationPage} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      !!['Storage', 'Construction'].find((prefix) => validationError.Field.startsWith(prefix)) ||
      !!validationError?.SubField?.startsWith('Address'),
    icon: <IoHomeOutline />
  },
  {
    path: '/app/jewelry/:id/criteria',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper policy={policy} pages={pages} component={CriteriaPage} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      validationError.Field.startsWith('PriorLosses') ||
      [
        'ZipCode',
        'UnderwritingFlags',
        'UnderwritingJustification',
        'AdditionalInsuredFirstName',
        'AdditionalInsuredLastName',
        'CurrentInsurerName',
        'Attachment'
      ].includes(validationError.Field) ||
      !!['FirstName', 'LastName', 'Phone', 'Occupation', 'Email'].find(
        (subfield) => validationError.SubField === subfield
      ),
    icon: <IoPersonOutline />
  },
  {
    path: '/app/jewelry/:id/coverage',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => (
          <PageWrapper
            policy={policy}
            pages={pages}
            component={getCoveragePage(
              'Review your coverage',
              'Oyster coverage is flexible and comprehensive by default. You can also add additional coverages to your policy.',
              () => sdkValidatePolicy(policy.ID)
            )}
          />
        )}
      </GetPolicyWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/jewelry/:id/payment',
    render: (props) => (
      <ExistingUserWrapper>
        {(account) => (
          <GetPolicyWrapper policyId={props.match.params.id}>
            {(policy) => (
              <PageWrapper
                policy={policy}
                pages={pages}
                component={getPaymentPage(
                  () =>
                    account ? userGetPaymentSession(policy.ID) : sdkGetPaymentSession(policy.ID),
                  window.location.origin + `/policy/${policy.ID}/complete?flow=jewelry`,
                  account
                )}
              />
            )}
          </GetPolicyWrapper>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: 'jewelry',
      page_index: current,
      route: pages[current]?.path,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <PageContainer width={700}>
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      <ErrorBoundary forceMobile>
        {current >= 0 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < current ? (
                  <LinkComponent
                    href={(() => {
                      const match = /\/(pol_.+)\//.exec(history.location.pathname);
                      if (match && match[1]) {
                        return pages[i].path.replace(':id', match[1]);
                      }
                      return pages[i].path;
                    })()}
                  >
                    {page.icon}
                  </LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={current}
            />
          </PageSection>
        )}
        <Switch>
          <Route
            exact
            path="/app/jewelry/ineligible"
            render={() => (
              <PageSection>
                <h1>Sorry!</h1>
                <p>
                  {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}').Message ||
                    null}
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                </div>
              </PageSection>
            )}
          ></Route>
          <Route exact path="/app/jewelry" render={() => <CollectJewelryInfoPage />} />
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
          <Redirect to="/app/jewelry" />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
