import * as React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import { Progress } from '@oysterjs/ui/Form/progress';

import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  ValidationError,
  Policy,
  ElectronicsProduct,
  ElectronicsProductValidationKeys,
  Product,
  DefaultFileRoles,
  AttachmentFile,
  ProductType
} from '@oysterjs/types';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import {
  IoArrowForward,
  IoBagCheckOutline,
  IoPricetagsOutline,
  IoDocumentTextOutline,
  IoPersonOutline,
  IoArrowBack
} from 'react-icons/io5';
import {
  sdkCreatePolicy,
  sdkCreateTemporaryAttachments,
  sdkGetPaymentSession,
  sdkUpdatePolicy,
  sdkValidatePolicy
} from '@oysterjs/core/api/sdk';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ExistingUserWrapper, GetPolicyWrapper, Page, PageProps, PageWrapper } from '../page';
import { getPaymentPage } from '../payment';
import { getCoveragePage } from '../coverage';
import { useUrlData } from '@oysterjs/core/url';
import apm from '@oysterjs/core/apm';
import { CollectElectronicsInfo, ElectronicsFormData } from './collect';
import { ProductInfoPage } from '../product';
import { getProductDisplayName } from '@oysterjs/core/policy';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { userGetPaymentSession } from '@oysterjs/core/api/user';

const CollectElectronicsInfoPage: React.FunctionComponent<
  React.PropsWithChildren<{ policy?: Policy }>
> = (props) => {
  const history = useHistory();
  const urlData = useUrlData<ElectronicsFormData>();
  const referralChannel = useReferralChannel();

  const productType = history.location.pathname.split('/')[2] as ProductType;
  const productDisplayName = getProductDisplayName(productType);

  const onSubmit = (product: Product) => {
    const uploadAttachmentHandler = () => {
      const details = product.Details as ElectronicsProduct;
      const newFiles = details._PurchaseReceiptFiles;
      if (newFiles && newFiles.length > 0) {
        return sdkCreateTemporaryAttachments(
          DefaultFileRoles.purchaseReceipt,
          newFiles,
          details.PurchaseReceiptFileIDs
        );
      }
      return Promise.resolve(new Array<AttachmentFile>());
    };

    return uploadAttachmentHandler().then((filesMetadata) => {
      const updatedDetails = {
        ...product.Details,
        _PurchaseReceiptFiles: undefined,
        PurchaseReceiptFileIDs: filesMetadata
          .map((metadata) => metadata.ID)
          .concat((product.Details as ElectronicsProduct).PurchaseReceiptFileIDs || [])
      };

      if (props.policy) {
        return sdkUpdatePolicy(
          {
            ...props.policy,
            InsuredItems: [
              {
                ...product,
                Details: updatedDetails
              }
            ]
          },
          true
        );
      }
      return sdkCreatePolicy(
        [
          {
            ...product,
            Details: updatedDetails
          }
        ],
        {
          integrationID: urlData.integrationID,
          referralChannel
        }
      );
    });
  };

  const onContinue = (policy?: Policy) => {
    if (!props.policy) {
      history.replace(`/app/${productType}/${policy?.ID}`);
    }
    history.push(`/app/${productType}/${props.policy?.ID || policy?.ID}/criteria`);
  };

  const price = props.policy?.InsuredItems?.[0].Price?.Amount;

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as ElectronicsProduct;
  }

  const initialFormData = props.policy
    ? {
        ProductType: productType,
        Price: price?.toString() || '',
        Details: (props.policy.InsuredItems[0].Details || {}) as ElectronicsProduct
      }
    : urlData?.appData
    ? urlData.appData
    : { ProductType: productType, Price: '', Details: {} as ElectronicsProduct };

  return (
    <ProductInfoPage
      title={`Tell us about your ${productDisplayName}`}
      description={`We need a bit of information about your ${productDisplayName} before we can show you coverage options.`}
      initialFormData={initialFormData}
      hasError={pages[0].hasError}
      onSubmit={onSubmit}
      onContinue={onContinue}
      component={CollectElectronicsInfo}
    />
  );
};

export const CriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>A bit about you</h1>
    <p>Tell us a little bit about yourself.</p>
    <ElectronicsCriteriaPage {...props} />
  </PageSection>
);

const ElectronicsCriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const [validationError, setValidationError] = React.useState<ValidationError>();

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [props.validationError]);

  const onChangeInsured = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          [k]: v
        }
      }
    });
  };

  const onChangeMultiInsured = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader title="Personal information" />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'FirstName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('FirstName', value);
            }}
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={
              !inputChanged && validationError?.SubField === 'LastName' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('LastName', value);
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged && validationError?.SubField === 'Email' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Email', value);
            }}
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged && validationError?.SubField === 'Phone' && validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeInsured('Phone', value);
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader title="Residential address" />
      <AddressInputForm
        showSecondLine
        singleSecondLine
        onChange={(address) => {
          onChangeMultiInsured([
            ['AddressLine1', address.streetAddress],
            ['AddressLine2', address.streetAddressLine2],
            ['AddressCity', address.city],
            ['AddressState', address.state],
            ['AddressZipCode', address.zipCode]
          ]);
        }}
        initialValue={{
          streetAddress: props.policy.Underwriting.Insured.AddressLine1,
          streetAddressLine2: props.policy.Underwriting.Insured.AddressLine2,
          city: props.policy.Underwriting.Insured.AddressCity,
          state: props.policy.Underwriting.Insured.AddressState,
          zipCode: props.policy.Underwriting.Insured.AddressZipCode
        }}
        validationError={{
          streetAddress:
            !inputChanged &&
            validationError?.SubField === 'AddressLine1' &&
            validationError?.Message,
          streetAddressLine2:
            !inputChanged &&
            validationError?.SubField === 'AddressLine2' &&
            validationError?.Message,
          city:
            !inputChanged &&
            validationError?.SubField === 'AddressCity' &&
            validationError?.Message,
          state:
            !inputChanged &&
            validationError?.SubField === 'AddressState' &&
            validationError?.Message,
          zipCode:
            !inputChanged &&
            validationError?.SubField === 'AddressZipCode' &&
            validationError?.Message
        }}
      />
      <PageSection noBorder noPadding centered>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            loading={props.loading}
            onClick={(e) => {
              e.preventDefault();
              props.onNext();
            }}
            icon={<IoArrowForward />}
            primary
          >
            Continue
          </Button>
        </ButtonContainer>
      </PageSection>
    </FormContainer>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/$PRODUCT_TYPE/:id?',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <CollectElectronicsInfoPage policy={policy} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      ElectronicsProductValidationKeys.indexOf(validationError.Field as keyof ElectronicsProduct) >
        -1 || validationError.Field === 'Attachment',
    icon: <IoPricetagsOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/criteria',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper policy={policy} pages={pages} component={CriteriaPage} />}
      </GetPolicyWrapper>
    ),
    hasError: () => true,
    icon: <IoPersonOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/coverage',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => (
          <PageWrapper
            policy={policy}
            pages={pages}
            component={getCoveragePage(
              'Review your coverage',
              'Oyster coverage is flexible and comprehensive by default. You can also add additional coverages to your policy.',
              () => sdkValidatePolicy(policy.ID)
            )}
          />
        )}
      </GetPolicyWrapper>
    ),
    hasError: () => false,
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/$PRODUCT_TYPE/:id/payment',
    render: (props) => (
      <ExistingUserWrapper>
        {(account) => (
          <GetPolicyWrapper policyId={props.match.params.id}>
            {(policy) => (
              <PageWrapper
                policy={policy}
                pages={pages}
                component={getPaymentPage(
                  () =>
                    account ? userGetPaymentSession(policy.ID) : sdkGetPaymentSession(policy.ID),
                  window.location.origin + `/policy/${policy.ID}/complete?flow=phone`,
                  account
                )}
              />
            )}
          </GetPolicyWrapper>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();
  const productType = history.location.pathname.split('/')[2] as ProductType;

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path.replace('$PRODUCT_TYPE', productType),
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: productType,
      page_index: current,
      route: pages[current]?.path.replace('$PRODUCT_TYPE', productType),
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <PageContainer width={700}>
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      <ErrorBoundary forceMobile>
        {current >= 0 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < current ? (
                  <LinkComponent
                    href={(() => {
                      const match = /\/(pol_.+)\//.exec(history.location.pathname);
                      if (match && match[1]) {
                        return pages[i].path
                          .replace(':id', match[1])
                          .replace('$PRODUCT_TYPE', productType);
                      }
                      return pages[i].path;
                    })()}
                  >
                    {page.icon}
                  </LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={current}
            />
          </PageSection>
        )}
        <Switch>
          <Route
            exact
            path={`/app/${productType}/ineligible`}
            render={() => (
              <PageSection>
                <h1>Sorry!</h1>
                <p>
                  {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}').Message ||
                    null}
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                </div>
              </PageSection>
            )}
          ></Route>
          <Route exact path={`/app/${productType}`} render={() => <CollectElectronicsInfoPage />} />
          {pages.map((page) => (
            <Route
              exact
              key={page.path}
              path={page.path.replace('$PRODUCT_TYPE', productType)}
              render={page.render}
            />
          ))}
          <Redirect to={`/app/${productType}`} />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
