import * as React from 'react';
import { IoPricetagOutline } from 'react-icons/io5';

import { Switch, matchPath, Route as BrowserRoute, useHistory, useRouteMatch } from 'react-router';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);
import {
  sdkCreatePolicy,
  sdkCreateTemporaryAttachments,
  sdkUpdatePolicy
} from '@oysterjs/core/api/sdk';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import apm from '@oysterjs/core/apm';
import { useUrlData } from '@oysterjs/core/url';
import {
  AttachmentFile,
  DefaultFileRoles,
  JewelryProduct,
  JewelryProductValidationKeys,
  Policy,
  PolicyType,
  Product,
  ValidationError
} from '@oysterjs/types';
import { GetPolicyWrapper, Page } from '../page';
import { ProductInfoPage } from '../product';
import { CollectJewelryInfo, JewelryFormData } from './collect';
import ChubbInsuranceApp from './chubb/index';
import MinicoInsuranceApp from './minico/index';

const CollectJewelryInfoPage: React.FunctionComponent<
  React.PropsWithChildren<{ policy?: Policy }>
> = (props) => {
  const urlData = useUrlData<JewelryFormData>();
  const history = useHistory();
  const referralChannel = useReferralChannel();

  const onSubmit = (product: Product) => {
    const uploadAttachmentHandler = () => {
      const details = product.Details as JewelryProduct;
      const newFiles = details._AppraisalFiles;
      if (newFiles && newFiles.length > 0) {
        return sdkCreateTemporaryAttachments(
          DefaultFileRoles.purchaseReceipt,
          newFiles,
          details.AppraisalFileIDs
        );
      }
      return Promise.resolve(new Array<AttachmentFile>());
    };

    return uploadAttachmentHandler().then((filesMetadata) => {
      const updatedDetails = {
        ...product.Details,
        _AppraisalFiles: undefined,
        AppraisalFileIDs: filesMetadata
          .map((metadata) => metadata.ID)
          .concat((product.Details as JewelryProduct).AppraisalFileIDs || [])
      };

      if (props.policy) {
        return sdkUpdatePolicy(
          {
            ...props.policy,
            InsuredItems: [
              {
                ...product,
                Details: updatedDetails
              }
            ]
          },
          true
        );
      }
      return sdkCreatePolicy(
        [
          {
            ...product,
            Details: updatedDetails
          }
        ],
        {
          integrationID: urlData.integrationID,
          referralChannel
        }
      );
    });
  };

  const onContinue = async (policy?: Policy) => {
    const currentPolicy = policy || props.policy;
    if (currentPolicy && currentPolicy.Type == PolicyType.chubbJewelry) {
      history.push(`/app/jewelry/v2/${currentPolicy.ID}/criteria`);
    } else if (currentPolicy && currentPolicy.Type == PolicyType.minicoJewelry) {
      history.push(`/app/jewelry/${currentPolicy.ID}/location`);
    } else {
      history.replace(`/app/jewelry/${currentPolicy?.ID}`);
    }
  };

  const price = props.policy?.InsuredItems?.[0].Price?.Amount;

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as JewelryProduct;
  }

  const initialFormData = props.policy
    ? {
        Price: price?.toString() || '',
        Name: props.policy.InsuredItems[0].Name,
        Description: props.policy.InsuredItems[0].Description,
        Details: props.policy.InsuredItems[0].Details as JewelryProduct
      }
    : urlData?.appData
    ? urlData.appData
    : { Price: '', Details: {} as JewelryProduct };

  return (
    <ProductInfoPage
      title="Tell us about your jewelry"
      description="We need a bit of information about your jewelry before we can show you coverage options."
      initialFormData={initialFormData}
      hasError={pages[0].hasError}
      onSubmit={onSubmit}
      onContinue={onContinue}
      component={CollectJewelryInfo}
    />
  );
};

const pages: Array<Page> = [
  {
    path: '/app/jewelry/:id?',
    render: (props) => (
      <PageContainer width={700}>
        <PageSection centered>
          <OysterLogo scale={1.5} inline />
        </PageSection>
        <GetPolicyWrapper policyId={props.match.params.id}>
          {(policy) => <CollectJewelryInfoPage policy={policy} />}
        </GetPolicyWrapper>
      </PageContainer>
    ),
    hasError: (validationError: ValidationError) =>
      JewelryProductValidationKeys.indexOf(validationError.Field as keyof JewelryProduct) > -1 ||
      validationError.Field === 'Attachment',
    icon: <IoPricetagOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: 'jewelry',
      page_index: current,
      route: pages[current]?.path,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <>
      <ErrorBoundary forceMobile>
        {current >= 0 && <PageSection noBorder noPadding />}
        <Switch>
          <Route
            exact
            path="/app/jewelry/ineligible"
            render={() => (
              <PageContainer width={700}>
                <PageSection centered>
                  <OysterLogo scale={1.5} inline />
                </PageSection>
                <PageSection>
                  <h1>Sorry!</h1>
                  <p>
                    {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}')
                      .Message || null}
                  </p>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                  </div>
                </PageSection>
              </PageContainer>
            )}
          ></Route>

          <Route
            exact
            path="/app/jewelry"
            render={() => (
              <PageContainer width={700}>
                <PageSection centered>
                  <OysterLogo scale={1.5} inline />
                </PageSection>
                <CollectJewelryInfoPage />
              </PageContainer>
            )}
          />
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
          <Route path="/app/jewelry/v2">
            <ChubbInsuranceApp />
          </Route>
          <Route path="/app/jewelry">
            <MinicoInsuranceApp />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};
