import { PageSection } from '@oysterjs/ui/Page';

import { PageProps } from './page';
import { AccountSummary, ActivationSource, ActivationTrigger } from '@oysterjs/types';
import { PaymentMethodInput, ShowDefaultPayment } from '@oysterjs/core/payment';

export const getPaymentPageText = (activationTrigger?: ActivationTrigger) => {
  if (!activationTrigger) {
    return (
      <p>
        You're almost ready to go. Finish the process by confirming your payment method. You will
        see an authorization on your card, but won't be charged until our team reviews your
        application and activates your coverage.
      </p>
    );
  }
  switch (activationTrigger.Source) {
    case ActivationSource.ManuallyActivate:
      return (
        <p>
          You're almost ready to go. Finish the process by confirming your payment method. You will
          see an authorization on your card, but won't be charged until you activate your policy on
          your dashboard.
        </p>
      );
    case ActivationSource.SetStartDate:
      return (
        <p>
          You're almost ready to go. Finish the process by confirming your payment method. You will
          see an authorization on your card, but won't be charged until your selected start date:{' '}
          <b>{activationTrigger.StartDate}</b> or when you manually activate your policy on your
          dashboard.
        </p>
      );
    case ActivationSource.TrackingNumber:
      return (
        <p>
          You're almost ready to go. Finish the process by confirming your payment method. You will
          see an authorization on your card, but won't be charged until your item has been delivered
          (Tracking number: <b>{activationTrigger.TrackingNumber}</b>) or when you manually activate
          your policy on your dashboard.
        </p>
      );
    default:
      return (
        <p>
          You're almost ready to go. Finish the process by confirming your payment method. You will
          see an authorization on your card, but won't be charged until our team reviews your
          application and activates your coverage.
        </p>
      );
  }
};

export const getPaymentPage = (
  getPaymentIntent: () => Promise<string>,
  returnUrl: string,
  account?: AccountSummary
): React.FunctionComponent<React.PropsWithChildren<PageProps>> => {
  const defaultPayment = account?.PaymentMethods?.find((paymentMethod) => paymentMethod.Default);

  return (props) => (
    <PageSection>
      <h1>Complete your purchase</h1>
      {getPaymentPageText(props.policy.Coverage.ActivationTrigger)}
      {!defaultPayment && (
        <PaymentMethodInput
          getPaymentIntent={getPaymentIntent}
          setupOnly={false}
          returnUrl={returnUrl}
          onBack={props.onBack}
          policyType={props.policy.Type}
          underwriter={props.policy.Underwriting.Underwriter}
        />
      )}
      {defaultPayment && (
        <ShowDefaultPayment
          confirmPaymentIntent={getPaymentIntent}
          returnUrl={returnUrl}
          defaultPayment={defaultPayment}
          onBack={props.onBack}
          policyType={props.policy.Type}
          underwriter={props.policy.Underwriting.Underwriter}
        />
      )}
    </PageSection>
  );
};
