import * as React from 'react';

import {
  ValidationError,
  Product,
  ElectronicsProduct,
  ProductType,
  ElectronicsType
} from '@oysterjs/types';
import { FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { FormColumn } from '@oysterjs/ui/Form/builder';
import { Select } from '@oysterjs/ui/Form/select';
import { TextInput } from '@oysterjs/ui/Form/text';
import { PageSection } from '@oysterjs/ui/Page';
import { Button } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { AttachmentUploader } from '@oysterjs/ui/Attachment';

export interface ElectronicsFormData {
  ProductType: ProductType;
  ZipCode?: string;
  Price: string;
  Details: ElectronicsProduct;
}

export const CollectElectronicsInfo: React.FunctionComponent<{
  loading?: boolean;
  validationError?: ValidationError;
  skipReceiptUpload?: boolean;
  formData: ElectronicsFormData;
  setData: (field: string, fn: (data: ElectronicsFormData) => ElectronicsFormData) => void;
  onContinue: (product: Product) => void;
}> = (props) => {
  const [validationError, setValidationError] = React.useState<ValidationError>();

  const productTypeDisplayName =
    props.formData.ProductType === ProductType.phone ? 'phone' : 'item';

  React.useEffect(() => {
    setValidationError(props.validationError);
  }, [JSON.stringify(props.validationError)]);

  const setData = (field: string, fn: (data: ElectronicsFormData) => ElectronicsFormData) => {
    if (validationError?.Field === field) {
      setValidationError(undefined);
    }
    props.setData(field, fn);
  };

  const validate = (formData: ElectronicsFormData) => {
    if (!formData.Details.Type && props.formData.ProductType === ProductType.electronics) {
      return {
        Field: 'Type',
        Message: 'Please choose a product type.'
      };
    }
    if (!formData.Details.Manufacturer) {
      return {
        Field: 'Manufacturer',
        Message: 'Please choose a brand.'
      };
    }
    if (!formData.Details.Model) {
      return {
        Field: 'Model',
        Message: 'Please enter a model.'
      };
    }
    if (!formData.Details.SerialNumber) {
      return {
        Field: 'SerialNumber',
        Message: 'Please enter a valid serial number.'
      };
    }
    if (!/^\$?[0-9]+(?:\.[0-9]+)?$/.test(formData.Price)) {
      return {
        Field: 'Price',
        Message: 'Please enter a valid price.'
      };
    }
    const fileLength =
      (formData.Details?.PurchaseReceiptFileIDs?.length || 0) +
      (formData.Details?._PurchaseReceiptFiles?.length || 0);
    if (!props.skipReceiptUpload && fileLength === 0) {
      return {
        Field: 'Attachment',
        Message: 'Please provide purchase receipts(s) for this phone.'
      };
    }
    return null;
  };

  return (
    <FormContainer>
      <FormRowHeader
        title="What are you insuring?"
        description={`Choose the brand of your ${productTypeDisplayName} and its full model name, like ${
          props.formData.ProductType === ProductType.phone
            ? `"iPhone 13" or "Galaxy S22"`
            : `"iPad Pro" or "Surface Pro 9"`
        }.`}
      />
      <FormRow>
        {props.formData.ProductType === ProductType.electronics && (
          <FormColumn title="Type">
            <Select
              options={[
                { value: '' },
                { displayValue: 'Laptop', value: 'laptop' },
                { displayValue: 'Tablet', value: 'tablet' },
                { displayValue: 'eReader', value: 'ereader_kindle' },
                { displayValue: 'Gaming console', value: 'gaming_system' },
                { displayValue: 'Camera or Other devices', value: 'small_electronics' }
              ]}
              error={validationError?.Field === 'Type' && validationError?.Message}
              onChange={(value) =>
                setData('Type', (prev) => ({
                  ...prev,
                  Details: { ...prev.Details, Type: value }
                }))
              }
              value={props.formData.Details.Type}
            />
          </FormColumn>
        )}
        <FormColumn title="Brand">
          <Select
            options={
              props.formData.ProductType === ProductType.phone
                ? [
                    { value: '' },
                    { value: 'Apple' },
                    { value: 'Google' },
                    { value: 'Huawei' },
                    { value: 'LG' },
                    { value: 'Motorola' },
                    { value: 'Oppo' },
                    { value: 'Samsung' },
                    { value: 'Xiaomi' },
                    { value: 'Other' }
                  ]
                : [
                    { value: '' },
                    { value: 'Apple' },
                    { value: 'Samsung' },
                    { value: 'Microsoft' },
                    { value: 'Sony' },
                    { value: 'LG' },
                    { value: 'Panasonic' },
                    { value: 'Fujitsu' },
                    { value: 'GE' },
                    { value: 'Bose' },
                    { value: 'Nikon' },
                    { value: 'Canon' },
                    { value: 'Other' }
                  ]
            }
            error={validationError?.Field === 'Manufacturer' && validationError?.Message}
            onChange={(value) =>
              setData('Manufacturer', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Manufacturer: value }
              }))
            }
            value={props.formData.Details.Manufacturer}
          />
        </FormColumn>
      </FormRow>
      <FormRow>
        <FormColumn title="Model">
          <TextInput
            error={validationError?.Field === 'Model' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Model', (prev) => ({
                ...prev,
                Details: { ...prev.Details, Model: value }
              }));
            }}
            value={props.formData.Details.Model}
          />
        </FormColumn>
        <FormColumn title="Serial Number" style={{ maxWidth: '48%' }}>
          <TextInput
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('SerialNumber', (prev) => ({
                ...prev,
                Details: { ...prev.Details, SerialNumber: value }
              }));
            }}
            error={validationError?.Field === 'SerialNumber' && validationError?.Message}
            value={props.formData.Details.SerialNumber}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title={`How much does your ${productTypeDisplayName} cost?`}
        description={`Enter the original purchase price of the ${productTypeDisplayName}.`}
      />
      <FormRow>
        <FormColumn>
          <TextInput
            currency={true}
            style={{ maxWidth: '200px' }}
            error={validationError?.Field === 'Price' && validationError?.Message}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setData('Price', (prev) => ({ ...prev, Price: value }));
            }}
            value={props.formData.Price}
          />
        </FormColumn>
      </FormRow>
      {!props.skipReceiptUpload && (
        <FormRow>
          <AttachmentUploader
            attachments={
              props.formData.Details?.PurchaseReceiptFileIDs?.map((fileId, index) => ({
                ID: fileId,
                Name: `Attachment #${index + 1}`,
                Type: '',
                Size: 0
              })) || []
            }
            title={'Purchase Receipt'}
            description={`To help us speed up the processing time of your policy, please upload the purchase receipt for this ${productTypeDisplayName}. Limit of 20 MB total size.`}
            multiple={false}
            allowDeleteExisting={false}
            onAttachmentRemoved={(index, _, file) => {
              if (file) {
                if (index >= 0) {
                  setData('Attachment', (prev) => {
                    const updatedFiles = prev.Details._PurchaseReceiptFiles;
                    updatedFiles?.splice(index, 1);

                    return {
                      ...prev,
                      Details: {
                        ...prev.Details,
                        _PurchaseReceiptFiles: updatedFiles
                      }
                    };
                  });
                }
                return Promise.resolve();
              } else {
                return Promise.resolve();
              }
            }}
            onAttachmentsAdded={(files) => {
              setData('Attachment', (prev) => ({
                ...prev,
                Details: {
                  ...prev.Details,
                  _PurchaseReceiptFiles: files
                }
              }));
              return Promise.resolve();
            }}
            validationError={
              validationError?.Field === 'Attachment' && validationError?.Message
                ? validationError
                : undefined
            }
          />
        </FormRow>
      )}
      <PageSection noBorder noPadding centered>
        <Button
          icon={<IoArrowForward />}
          primary
          loading={props.loading}
          onClick={(e) => {
            e.preventDefault();

            const err = validate(props.formData);
            if (err) {
              setValidationError(err);
              return;
            }

            props.onContinue({
              Type: ProductType.electronics,
              SKU: '',
              Name: `${
                props.formData.Details.Manufacturer === 'Other'
                  ? ''
                  : props.formData.Details.Manufacturer
              } ${props.formData.Details.Model}`.trim(),
              Description: '',
              ImageURL:
                props.formData.ProductType === ProductType.phone
                  ? 'https://dashboard.withoyster.com/images/phone_insurance.svg'
                  : '',
              Quantity: 1,
              Price: {
                Amount: parseFloat(props.formData.Price.replace(/[^.0-9]/, '')),
                Currency: 'usd'
              },
              Details: {
                Type: (() => {
                  if (props.formData.ProductType === ProductType.phone) {
                    if (props.formData.Details.Manufacturer === 'Apple') {
                      return ElectronicsType.iPhone;
                    }
                    return ElectronicsType.smartPhone;
                  } else {
                    if (
                      props.formData.Details.Manufacturer === 'Apple' &&
                      props.formData.ProductType === ProductType.electronics &&
                      props.formData.Details.Type === ElectronicsType.tablet
                    ) {
                      return ElectronicsType.iPad;
                    }
                  }
                  return props.formData.Details.Type;
                })(),
                Manufacturer: props.formData.Details.Manufacturer,
                Model: props.formData.Details.Model,
                SerialNumber: props.formData.Details.SerialNumber,
                PurchaseReceiptFileIDs: props.formData.Details.PurchaseReceiptFileIDs,
                _PurchaseReceiptFiles: props.formData.Details._PurchaseReceiptFiles
              }
            });
          }}
        >
          Continue
        </Button>
      </PageSection>
    </FormContainer>
  );
};
