import * as React from 'react';
import {
  Switch,
  Route as BrowserRoute,
  Redirect,
  useRouteMatch,
  useHistory,
  matchPath
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import { Progress } from '@oysterjs/ui/Form/progress';

import { OysterLogo } from '@oysterjs/ui/Logo';
import { PageContainer, PageSection } from '@oysterjs/ui/Page';
import {
  BikeUsageType,
  MarkelBikeCriteria,
  ValidationError,
  Policy,
  BikeProduct,
  Product,
  BikeProductValidationKeys,
  PolicyType
} from '@oysterjs/types';
import { TextInput } from '@oysterjs/ui/Form/text';
import { Button, ButtonContainer, LinkComponent } from '@oysterjs/ui/Button';
import {
  IoArrowBack,
  IoArrowForward,
  IoBagCheckOutline,
  IoBicycleOutline,
  IoDocumentTextOutline,
  IoPersonOutline
} from 'react-icons/io5';
import { Select } from '@oysterjs/ui/Form/select';
import {
  sdkCreatePolicy,
  sdkGetPaymentSession,
  sdkUpdatePolicy,
  sdkValidatePolicy
} from '@oysterjs/core/api/sdk';
import { ExistingUserWrapper, GetPolicyWrapper, Page, PageProps, PageWrapper } from '../page';
import { getPaymentPage } from '../payment';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';
import { getCoveragePage } from '../coverage';
import { useUrlData } from '@oysterjs/core/url';
import { BikeFormData, CollectBikeInfo } from './collect';
import apm from '@oysterjs/core/apm';
import { MultiProductInfoPage } from '../product';
import { useReferralChannel } from '@oysterjs/core/analytics/attribution';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { userGetPaymentSession } from '@oysterjs/core/api/user';
import { SubmissionDisclosure } from '@oysterjs/core/payment';

const CollectBikeInfoPage: React.FunctionComponent<React.PropsWithChildren<{ policy?: Policy }>> = (
  props
) => {
  const urlData = useUrlData<BikeFormData>();
  const history = useHistory();
  const referralChannel = useReferralChannel();

  const onSubmit = async (products: Product[]) => {
    let res: { Policy: Policy; NextValidationError?: ValidationError } | null = null;
    try {
      if (props.policy) {
        res = await sdkUpdatePolicy(
          {
            ...props.policy,
            InsuredItems: products
          },
          true
        );
      } else {
        res = await sdkCreatePolicy(products, {
          integrationID: urlData.integrationID,
          referralChannel
        });
      }
    } finally {
      if (res?.Policy) {
        window.history.replaceState(null, '', `/app/bike/${res.Policy.ID}`);
      }
    }

    return res;
  };

  const onContinue = (policy?: Policy) => {
    if (!props.policy) {
      history.replace(`/app/bike/${policy?.ID}`);
    }
    history.push(`/app/bike/${props.policy?.ID || policy?.ID}/criteria`);
  };

  if (urlData?.appData && !urlData.appData.Details) {
    urlData.appData.Details = {} as BikeProduct;
  }

  const formDataConstructor = (product?: Product) => ({
    Price: product?.Price?.Amount?.toString() || '',
    HasNotReceived: product?.HasNotReceived || false,
    Details: {
      Type: (product?.Details as BikeProduct)?.Type || '',
      FrameType: (product?.Details as BikeProduct)?.FrameType || '',
      PowerSource: (product?.Details as BikeProduct)?.PowerSource || '',
      CanUnlock: (product?.Details as BikeProduct)?.CanUnlock || false,
      IsPendingSerialNumber: (product?.Details as BikeProduct)?.IsPendingSerialNumber || false,
      ModelYear: (product?.Details as BikeProduct)?.ModelYear || '',
      PurchaseYear: (product?.Details as BikeProduct)?.PurchaseYear || '',
      Make: (product?.Details as BikeProduct)?.Make || '',
      Model: (product?.Details as BikeProduct)?.Model || '',
      FrameSerialNumber: (product?.Details as BikeProduct)?.FrameSerialNumber || '',
      IsSecondHandPurchase: (product?.Details as BikeProduct)?.IsSecondHandPurchase || false,
      TotalInsuredValue: (product?.Details as BikeProduct)?.TotalInsuredValue || {
        Amount: '',
        Currency: 'usd'
      }
    } as unknown as BikeProduct
  });

  const products = props.policy?.InsuredItems?.length
    ? props.policy.InsuredItems
    : urlData?.appData
    ? [{ Price: { Amount: urlData.appData.Price }, Details: urlData.appData.Details }]
    : [];

  return (
    <MultiProductInfoPage
      title="Your bikes"
      description={
        <>
          Tell us about the bikes you'd like to insure. You can get up to a <b>15% discount</b> for
          insuring multiple bikes.
        </>
      }
      formDataConstructor={formDataConstructor}
      products={products as Product[]}
      hasError={pages[0].hasError}
      onSubmit={onSubmit}
      onContinue={onContinue}
      component={CollectBikeInfo}
    />
  );
};

export const CriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (
  props
) => (
  <PageSection>
    <h1>A bit about you</h1>
    <p>Tell us a little bit about yourself and how you use your bike.</p>
    <BikeCriteriaPage {...props} />
  </PageSection>
);

const BikeCriteriaPage: React.FunctionComponent<React.PropsWithChildren<PageProps>> = (props) => {
  const [inputChanged, setInputChanged] = React.useState(false);
  const criteria = props.policy.Underwriting.Criteria as MarkelBikeCriteria;
  const [state, setState] = React.useState(props.policy.Underwriting.Insured.AddressState || '');

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  const onChange = <T,>(k: string, v: T) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...criteria,
          [k]: v
        }
      }
    });
  };

  const onChangeMultiCriteria = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Criteria: {
          ...props.policy.Underwriting.Criteria,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  const onChangeMultiInsured = <T,>(vals: [string, T][]) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          ...Object.fromEntries(vals)
        }
      }
    });
  };

  const onChangeInsuredDateOfBirth = (day: string, month: string, year: string) => {
    setInputChanged(true);
    props.onUpdate({
      ...props.policy,
      Underwriting: {
        ...props.policy.Underwriting,
        Insured: {
          ...props.policy.Underwriting.Insured,
          DateOfBirth: {
            Day: day,
            Month: month,
            Year: year
          }
        }
      }
    });
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        props.onNext();
      }}
    >
      <FormRowHeader title="Personal information" />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'FirstName' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['FirstName', value]]);
            }}
            autoComplete="given-name"
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'LastName' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['LastName', value]]);
            }}
            autoComplete="family-name"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Email' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['Email', value]]);
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Phone' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeMultiInsured([['Phone', value]]);
            }}
            inputMode="tel"
            autoComplete="tel"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Gender identity">
          <Select
            options={[
              { value: '' },
              { displayValue: 'Male', value: 'male' },
              { displayValue: 'Female', value: 'female' },
              { displayValue: 'Non-binary', value: 'non_binary' },
              { displayValue: 'Other', value: 'other' }
            ]}
            error={
              !inputChanged &&
              props.validationError?.SubField === 'Gender' &&
              props.validationError?.Message
            }
            value={props.policy.Underwriting.Insured.Gender}
            onChange={(value) => onChangeMultiInsured([['Gender', value]])}
          />
        </FormColumn>
        <FormColumn title="Date of birth">
          <DateOfBirthInput
            error={
              !inputChanged &&
              props.validationError?.SubField === 'DateOfBirth' &&
              props.validationError?.Message
            }
            initialValue={[
              props.policy.Underwriting.Insured.DateOfBirth?.Day || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Month || '',
              props.policy.Underwriting.Insured.DateOfBirth?.Year || ''
            ]}
            onChange={onChangeInsuredDateOfBirth}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader title="Residential address" />
      <AddressInputForm
        showSecondLine
        singleSecondLine
        onChange={(address) => {
          onChangeMultiInsured([
            ['AddressLine1', address.streetAddress],
            ['AddressLine2', address.streetAddressLine2],
            ['AddressCity', address.city],
            ['AddressState', address.state],
            ['AddressZipCode', address.zipCode]
          ]);
          setState(address.state || '');
        }}
        initialValue={{
          streetAddress: props.policy.Underwriting.Insured.AddressLine1,
          streetAddressLine2: props.policy.Underwriting.Insured.AddressLine2,
          city: props.policy.Underwriting.Insured.AddressCity,
          state: props.policy.Underwriting.Insured.AddressState,
          zipCode: props.policy.Underwriting.Insured.AddressZipCode
        }}
        validationError={{
          streetAddress:
            !inputChanged &&
            props.validationError?.SubField === 'AddressLine1' &&
            props.validationError?.Message,
          streetAddressLine2:
            !inputChanged &&
            props.validationError?.SubField === 'AddressLine2' &&
            props.validationError?.Message,
          city:
            !inputChanged &&
            props.validationError?.SubField === 'AddressCity' &&
            props.validationError?.Message,
          state:
            !inputChanged &&
            props.validationError?.SubField === 'AddressState' &&
            props.validationError?.Message,
          zipCode:
            !inputChanged &&
            props.validationError?.SubField === 'AddressZipCode' &&
            props.validationError?.Message
        }}
      />
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '16px 0px 0px 0px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            onChange('ResidentialIsStorage', !criteria.ResidentialIsStorage);
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label="This is also my residential address"
              checked={criteria.ResidentialIsStorage}
              onChange={() => {
                onChange('ResidentialIsStorage', !criteria.ResidentialIsStorage);
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>This is also the bike storage address</RequirementTitle>
            <RequirementDescription>
              Check this box if the bike is also stored in this address.
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {!criteria.ResidentialIsStorage && (
        <>
          <FormRowHeader
            title="Storage address"
            description="Enter the address that this bike will be stored at."
          />
          <AddressInputForm
            showSecondLine
            singleSecondLine
            onChange={(address) => {
              onChangeMultiCriteria([
                ['StorageAddressLine1', address.streetAddress],
                ['StorageAddressLine2', address.streetAddressLine2],
                ['StorageCity', address.city],
                ['StorageState', address.state],
                ['StorageZipCode', address.zipCode]
              ]);
            }}
            initialValue={{
              streetAddress: criteria.StorageAddressLine1,
              streetAddressLine2: criteria.StorageAddressLine2,
              city: criteria.StorageCity,
              state: criteria.StorageState,
              zipCode: criteria.StorageZipCode
            }}
            validationError={{
              streetAddress:
                !inputChanged &&
                props.validationError?.Field === 'StorageAddressLine1' &&
                props.validationError?.Message,
              streetAddressLine2:
                !inputChanged &&
                props.validationError?.SubField === 'StorageAddressLine2' &&
                props.validationError?.Message,
              city:
                !inputChanged &&
                props.validationError?.Field === 'StorageCity' &&
                props.validationError?.Message,
              state:
                !inputChanged &&
                props.validationError?.Field === 'StorageState' &&
                props.validationError?.Message,
              zipCode:
                !inputChanged &&
                props.validationError?.Field === 'StorageZipCode' &&
                props.validationError?.Message
            }}
          />
        </>
      )}
      {(state.replace(/ /g, '').toUpperCase() === 'NY' ||
        state.replace(/ /g, '').toUpperCase() === 'NEWYORK') && (
        <>
          <FormRowHeader
            title="How many years of experience do you have?"
            description="Experienced e-bike riders can get significant discounts on their insurance."
          />
          <FormColumn style={{ maxWidth: 'calc(50% - 8px)' }}>
            <Select
              options={[
                { value: '' },
                { displayValue: 'No experience', value: '0' },
                { displayValue: '1 year', value: '1' },
                { displayValue: '2 years', value: '2' },
                { displayValue: '3+ years', value: '3+' }
              ]}
              error={
                !inputChanged &&
                props.validationError?.Field === 'YearsOfExperience' &&
                props.validationError?.Message
              }
              value={criteria.YearsOfExperience}
              onChange={(value) => {
                onChange('YearsOfExperience', value);
              }}
            />
          </FormColumn>
        </>
      )}
      <FormRowHeader
        title="How will you use this bike?"
        description="Oyster can help you get the coverage you need based on how you use your bike. Select all that apply."
      />
      <FormRow
        error={
          !inputChanged && props.validationError?.Field === 'UsageTypes'
            ? props.validationError?.Message
            : undefined
        }
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {[
            {
              value: BikeUsageType.casual,
              name: 'Casually',
              description: 'You use your bike for recreation.'
            },
            {
              value: BikeUsageType.fitness,
              name: 'Fitness',
              description: 'You use your bike for fitness or exercise.'
            },
            {
              value: BikeUsageType.commute,
              name: 'Commute',
              description: 'You use your bike to commute for work or school.'
            },
            {
              value: BikeUsageType.competition,
              name: 'Competition',
              description: 'You use your bike to train or compete in sporting events.'
            }
          ].map((option) => (
            <RequirementItemContainer
              key={option.value}
              style={{ padding: '0px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                const s = new Set(criteria.UsageTypes);
                if (s.has(option.value)) {
                  s.delete(option.value);
                } else {
                  s.add(option.value);
                }
                onChange('UsageTypes', [...s]);
              }}
            >
              <RequirementCheckboxContainer>
                <Checkbox
                  label={option.name}
                  checked={criteria.UsageTypes.includes(option.value)}
                  onChange={() => {
                    const s = new Set(criteria.UsageTypes);
                    if (s.has(option.value)) {
                      s.delete(option.value);
                    } else {
                      s.add(option.value);
                    }
                    onChange('UsageTypes', [...s]);
                  }}
                />
              </RequirementCheckboxContainer>
              <RequirementContentContainer>
                <RequirementTitle>{option.name}</RequirementTitle>
                <RequirementDescription>{option.description}</RequirementDescription>
              </RequirementContentContainer>
            </RequirementItemContainer>
          ))}
        </div>
      </FormRow>
      <ButtonContainer center>
        {props.onBack && (
          <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
            Back
          </Button>
        )}
        <Button
          loading={props.loading}
          onClick={(e) => {
            e.preventDefault();
            props.onNext();
          }}
          icon={<IoArrowForward />}
          primary
        >
          Continue
        </Button>
      </ButtonContainer>
      <SubmissionDisclosure policyType={PolicyType.markelBike} noPadding />
    </FormContainer>
  );
};

const pages: Array<Page> = [
  {
    path: '/app/bike/:id?',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <CollectBikeInfoPage policy={policy} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      BikeProductValidationKeys.indexOf(validationError.Field as keyof BikeProduct) > -1,
    icon: <IoBicycleOutline />
  },
  {
    path: '/app/bike/:id/criteria',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => <PageWrapper pages={pages} policy={policy} component={CriteriaPage} />}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      validationError.Field.startsWith('PriorLosses') ||
      [
        'StorageAddressLine1',
        'StorageCity',
        'StorageState',
        'StorageZipCode',
        'YearsOfExperience',
        'UsageTypes'
      ].includes(validationError.Field) ||
      !![
        'FirstName',
        'LastName',
        'Phone',
        'Email',
        'DateOfBirth',
        'Gender',
        'AddressLine1',
        'AddressLine2',
        'AddressCity',
        'AddressState',
        'AddressZipCode'
      ].find((subfield) => validationError.SubField === subfield),
    icon: <IoPersonOutline />
  },
  {
    path: '/app/bike/:id/coverage',
    render: (props) => (
      <GetPolicyWrapper policyId={props.match.params.id}>
        {(policy) => (
          <PageWrapper
            policy={policy}
            pages={pages}
            component={getCoveragePage(
              'Review your coverage',
              'Oyster coverage is flexible and comprehensive by default. You can also add additional coverages to your policy.',
              () => sdkValidatePolicy(policy.ID)
            )}
          />
        )}
      </GetPolicyWrapper>
    ),
    hasError: (validationError: ValidationError) =>
      !!['Source', 'TrackingNumber', 'StartDate'].find(
        (subfield) => validationError.SubField == subfield
      ),
    icon: <IoDocumentTextOutline />
  },
  {
    path: '/app/bike/:id/payment',
    render: (props) => (
      <ExistingUserWrapper>
        {(account) => (
          <GetPolicyWrapper policyId={props.match.params.id}>
            {(policy) => (
              <PageWrapper
                policy={policy}
                pages={pages}
                component={getPaymentPage(
                  () =>
                    account ? userGetPaymentSession(policy.ID) : sdkGetPaymentSession(policy.ID),
                  window.location.origin + `/policy/${policy.ID}/complete?flow=bike`,
                  account
                )}
              />
            )}
          </GetPolicyWrapper>
        )}
      </ExistingUserWrapper>
    ),
    hasError: (validationError: ValidationError) => validationError.Field === 'Insured',
    icon: <IoBagCheckOutline />
  }
];

export default (): JSX.Element => {
  // Side effect to trigger re-renders when the page changes
  useRouteMatch();
  const history = useHistory();

  const current = pages.findIndex((page) =>
    matchPath(window.location.pathname, {
      path: page.path,
      exact: true
    })
  );

  React.useEffect(() => {
    apm().sendEvent('oyster.d2c.flow_page_change', {
      flow_type: 'bike',
      page_index: current,
      route: pages[current]?.path,
      path: window.location.pathname,
      query: window.location.search
    });
  }, [current]);

  return (
    <PageContainer width={700}>
      <PageSection centered>
        <OysterLogo scale={1.5} inline />
      </PageSection>
      <ErrorBoundary forceMobile>
        {current >= 0 && (
          <PageSection noBorder noPadding>
            <Progress
              steps={pages.map((page, i) =>
                i < current ? (
                  <LinkComponent
                    href={(() => {
                      const match = /\/(pol_.+)\//.exec(history.location.pathname);
                      if (match && match[1]) {
                        return pages[i].path.replace(':id', match[1]);
                      }
                      return pages[i].path;
                    })()}
                  >
                    {page.icon}
                  </LinkComponent>
                ) : (
                  page.icon
                )
              )}
              currentStep={current}
            />
          </PageSection>
        )}
        <Switch>
          <Route
            exact
            path="/app/bike/ineligible"
            render={() => (
              <PageSection>
                <h1>Sorry!</h1>
                <p>
                  {JSON.parse(window.localStorage.getItem('underwriting_error') || '{}').message ||
                    'Thank you for your interest. Unfortunately your property is not eligible for coverage under our program.'}
                </p>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <img width={260} height={254} src="/images/underwriting_error.svg" alt="" />
                </div>
              </PageSection>
            )}
          ></Route>
          <Route exact path="/app/bike" render={() => <CollectBikeInfoPage />} />
          {pages.map((page) => (
            <Route exact key={page.path} path={page.path} render={page.render} />
          ))}
          <Redirect to="/app/bike" />
        </Switch>
      </ErrorBoundary>
    </PageContainer>
  );
};
