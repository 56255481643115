import * as React from 'react';
import styled from 'styled-components';

import { PageSection } from '@oysterjs/ui/Page';
import { CoveragePageSection, CoverageItem } from '@oysterjs/ui/CoverageItem';
import { PageProps } from './page';
import {
  ActivationSource,
  ActivationTrigger,
  BooleanOption,
  Cadence,
  DiscreteOption,
  Option,
  OptionType,
  Policy,
  PolicyType,
  Price
} from '@oysterjs/types';
import { Select, UnstyledSelect } from '@oysterjs/ui/Form/select';
import { OptionGroup } from '@oysterjs/ui/Form/option';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { getSamplePolicyUrl } from '@oysterjs/core/statics';
import { getPolicyCoverageItems, getPolicyOptionalCoverageItem } from '@oysterjs/core/policy';
import { Spinner } from '@oysterjs/ui/Spinner';
import { useAnalytics } from '@oysterjs/core/analytics/analytics';
import { GetOysterEventNames } from '@oysterjs/core/analytics/googletags';
import { useHistory } from 'react-router';
import { ErrorType } from '@oysterjs/core/errors';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import { TextInput } from '@oysterjs/ui/Form/text';
import { FormColumn, FormRow } from '@oysterjs/ui/Form/builder';
import { DateOfBirthInput } from '@oysterjs/ui/Form/dob';

export const getCoveragePage =
  (
    title: string,
    description: string,
    getPremium: (updatedPolicy?: Policy) => Promise<Policy>,
    showLoading?: boolean,
    subDescription?: string,
    atomicPriceUpdate?: boolean
  ): React.FunctionComponent<React.PropsWithChildren<PageProps>> =>
  (props) => (
    <PolicyCoveragePage
      policy={props.policy}
      loading={props.loading}
      onNext={props.onNext}
      onBack={props.onBack}
      onUpdate={props.onUpdate}
      validationError={props.validationError}
      getPremium={getPremium}
      addButtonText="Continue"
      title={title}
      description={description}
      showLoading={showLoading}
      subDescription={subDescription}
      atomicPriceUpdate={atomicPriceUpdate}
    />
  );

const PolicyOptionBox = styled.div`
  padding-left: 30px;
  display: flex;
  flex-direction: column;
`;

const CoveragePriceAmount = styled.span`
  font-weight: 600;
  font-size: 2em;
  vertical-align: baseline;
`;

const CoveragePriceCadence = styled.span`
  text-transform: uppercase;
  display: block;
  font-size: 0.9em;
`;

export const CoveragePrice: React.FunctionComponent<{
  price: Price;
  cadence: Cadence;
  displayCadence?: Cadence;
  loading?: boolean;
  extraFooter?: string;
}> = (props) => {
  const annualPrice =
    props.cadence === Cadence.month ? props.price.Amount * 12 : props.price.Amount;
  const displayCadence = props.displayCadence || Cadence.year;
  const displayPrice = displayCadence == Cadence.year ? annualPrice : annualPrice / 12;
  const showBilledAnnually = displayCadence === Cadence.month;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '3px',
          opacity: props.loading ? '0.3' : '1.0',
          transition: 'all 0.15s ease-in-out',
          alignItems: 'baseline'
        }}
      >
        <CoveragePriceAmount>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: props.price.Currency,
            minimumFractionDigits: Number.isInteger(displayPrice) ? 0 : 2,
            maximumFractionDigits: 2
          }).format(displayPrice)}
        </CoveragePriceAmount>
        <CoveragePriceCadence>/{displayCadence}</CoveragePriceCadence>
        <div
          style={{
            paddingLeft: '2px',
            opacity: props.loading ? '1.0' : '0',
            transition: 'all 0.12s ease-in-out',
            userSelect: 'none'
          }}
        >
          <Spinner color="#000000" size={12} />
        </div>
      </div>
      {showBilledAnnually && (
        <div style={{ fontSize: '0.75em', color: '#999999' }}>
          Billed annually at{' '}
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: props.price.Currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(props.price.Amount)}
          /{props.cadence}
          {props.extraFooter ? '. ' + props.extraFooter : ''}
        </div>
      )}
      {!showBilledAnnually && props.extraFooter && (
        <div style={{ fontSize: '0.75em', color: '#999999' }}>{props.extraFooter}</div>
      )}
    </div>
  );
};

export const PolicyCoveragePage: React.FunctionComponent<
  PageProps & {
    title?: string;
    description?: string | JSX.Element;
    subDescription?: string;
    addButtonText?: string;
    priceDisplayCadence?: Cadence;
    showLoading?: boolean;
    isWidget?: boolean;
    atomicPriceUpdate?: boolean;
    getPremium: (updatedPolicy?: Policy) => Promise<Policy>;
  }
> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState<Price>();
  const [underwriter, setUnderwriter] = React.useState<string>('');
  const [inputChanged, setInputChanged] = React.useState(false);
  const analytics = useAnalytics();
  const history = useHistory();
  const showAltText =
    props.policy.Type == PolicyType.chubbJewelry &&
    props.policy.InsuredItems.reduce((partialSum, item) => partialSum + item.Price.Amount, 0) >
      200000;

  const [year, month, day] = (props.policy.Coverage.ActivationTrigger?.StartDate || '')
    .split('-')
    .map((v) => v || '');

  React.useEffect(() => {
    if (!props.loading) {
      setInputChanged(false);
    }
  }, [props.loading]);

  const onUpdate = (updatedPolicy: Policy, forceUpdate?: boolean) =>
    props.atomicPriceUpdate
      ? props.onUpdate(updatedPolicy, false)
      : props.onUpdate(updatedPolicy, forceUpdate);

  const loadPrice = async (updatedPolicy?: Policy) =>
    props
      .getPremium(updatedPolicy)
      .then((res) => {
        analytics.event(GetOysterEventNames.ReviewCoverage, {
          policy_id: props.policy.ID,
          policy_type: props.policy.Type,
          value: res.Price?.Amount.toString() || '0.0',
          currency: res.Price?.Currency || 'USD',
          transaction_id: props.policy.ID
        });
        setPrice(res.Price);
        setUnderwriter(res.Underwriting.Underwriter || '');
      })
      .catch((error) => {
        if (error.type() == ErrorType.underwritingError) {
          switch (props.policy.Type) {
            case PolicyType.chubbJewelry:
              history.push(`/app/${props.policy.InsuredItems[0].Type}/v2/ineligible`);
              break;
            default:
              history.push(`/app/${props.policy.InsuredItems[0].Type}/ineligible`);
          }
        }
      })
      .finally(() => setLoading(false));

  // There's an underlying issue that causes this component to mount and
  // unmount repeatedly, so this patch prevents us from spawning very
  // expensive requests to the backend by waiting 1 second before sending
  // the request, and canceling the wait if the component unmounts.
  React.useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => loadPrice().finally(() => setLoading(false)), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const onUpdateCoverageItem = (item: Option) => {
    if (loading) {
      return;
    }
    setLoading(true);
    onUpdate(
      {
        ...props.policy,
        Coverage: {
          ...props.policy.Coverage,
          Options: props.policy.Coverage.Options.map((existingItem) =>
            existingItem.ID === item.ID ? item : existingItem
          )
        }
      },
      true
    )
      .then(loadPrice)
      .finally(() => setLoading(false));
  };

  const onUpdateDeductible = (deductible: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    onUpdate(
      {
        ...props.policy,
        Coverage: {
          ...props.policy.Coverage,
          Deductible: {
            ...props.policy.Coverage.Deductible,
            Value: deductible
          }
        }
      },
      true
    )
      .then(loadPrice)
      .finally(() => setLoading(false));
  };

  const onUpdateActivationTrigger = (trigger?: ActivationTrigger) => {
    setInputChanged(true);
    onUpdate(
      {
        ...props.policy,
        Coverage: {
          ...props.policy.Coverage,
          ActivationTrigger:
            !trigger && !props.policy.Coverage.ActivationTrigger
              ? {
                  Source: ActivationSource.SetStartDate
                }
              : trigger
        }
      },
      false
    );
  };

  const handleToggle = (item: Option) => {
    onUpdateCoverageItem({
      ...item,
      Enabled: !item.Enabled
    });
  };

  const onUpdateCoverage = (item: Option) => {
    onUpdateCoverageItem({
      ...item,
      Option: item.Option
    });
  };

  const renderDescription = (item: Option) => {
    switch (item.Type) {
      case OptionType.boolean: {
        const opt = item.Option as BooleanOption;
        if (opt.ValueLabel) {
          return 'up to ' + (item.Option as BooleanOption).ValueLabel;
        }
        return undefined;
      }
      case OptionType.discrete: {
        const opt = item.Option as DiscreteOption;
        if (item.Enabled) {
          return (
            <UnstyledSelect
              selected={opt.Value}
              options={opt.AllowedValues.map((v) => ({
                value: v
              }))}
              disabled={loading}
              onChange={(newValue) =>
                onUpdateCoverage({
                  ...item,
                  Option: {
                    ...opt,
                    Value: newValue
                  }
                })
              }
            />
          );
        }
        return undefined;
      }
      default:
        return undefined;
    }
  };

  const coveragePage = (
    <>
      <h1>{props.title}</h1>
      {props.description && (
        <p>
          {props.description}{' '}
          <a target="_blank" href={getSamplePolicyUrl(props.policy.Type)}>
            See policy summary
          </a>
          .
        </p>
      )}
      {props.subDescription && <p>{props.subDescription}</p>}
      <CoveragePageSection title="Your Base Coverage">
        {getPolicyCoverageItems(props.policy.Type).map((item) => (
          <CoverageItem key={item.title} {...item} />
        ))}
      </CoveragePageSection>
      {props.policy.Coverage.Options?.length > 0 && (
        <CoveragePageSection title="Add Additional Coverage">
          <OptionGroup
            multi
            loading={props.loading || loading}
            name="coverage"
            options={props.policy.Coverage.Options.map((item) => ({
              ...getPolicyOptionalCoverageItem(item.ID),
              enabled: item.Enabled,
              disabled: item.Required || loading,
              titleRight: renderDescription(item),
              onSelect: () => handleToggle(item)
            }))}
          />
        </CoveragePageSection>
      )}
      {props.policy.InsuredItems.some((i) => i.HasNotReceived) && (
        <CoveragePageSection title="Policy Options">
          <RequirementItemContainer
            style={{ padding: '0 0', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onUpdateActivationTrigger();
            }}
          >
            <RequirementCheckboxContainer>
              <Checkbox
                label="Start coverage later"
                checked={!!props.policy.Coverage.ActivationTrigger}
                onChange={() => onUpdateActivationTrigger()}
              />
            </RequirementCheckboxContainer>
            <RequirementContentContainer>
              <RequirementTitle>Start coverage later</RequirementTitle>
              <RequirementDescription>
                Check this box to submit your application now but start your coverage at a later
                time. If this box is <b>not</b> checked, your coverage will be effective when your
                policy is approved.
              </RequirementDescription>
            </RequirementContentContainer>
          </RequirementItemContainer>
        </CoveragePageSection>
      )}
      {props.policy.Coverage.ActivationTrigger && (
        <PolicyOptionBox>
          <FormRow breakMobile>
            <FormColumn>
              <Select
                error={
                  !inputChanged &&
                  props.validationError?.SubField === 'Source' &&
                  props.validationError?.Message
                }
                options={[
                  { displayValue: 'Tracking Number', value: ActivationSource.TrackingNumber },
                  { displayValue: 'Set Start Date', value: ActivationSource.SetStartDate },
                  {
                    displayValue: 'Manually Activate Later',
                    value: ActivationSource.ManuallyActivate
                  }
                ]}
                onChange={(value) => {
                  onUpdateActivationTrigger({
                    Source: value
                  });
                }}
                value={props.policy.Coverage.ActivationTrigger?.Source}
              />
            </FormColumn>
            <FormColumn>
              {props.policy.Coverage.ActivationTrigger.Source ==
                ActivationSource.TrackingNumber && (
                <TextInput
                  error={
                    !inputChanged &&
                    props.validationError?.SubField === 'TrackingNumber' &&
                    props.validationError?.Message
                  }
                  value={props.policy.Coverage.ActivationTrigger?.TrackingNumber}
                  onChange={(e) => {
                    onUpdateActivationTrigger({
                      Source: ActivationSource.TrackingNumber,
                      TrackingNumber: e.currentTarget.value
                    });
                  }}
                />
              )}
              {props.policy.Coverage.ActivationTrigger.Source == ActivationSource.SetStartDate && (
                <DateOfBirthInput
                  onChange={(day, month, year) => {
                    onUpdateActivationTrigger({
                      Source: ActivationSource.SetStartDate,
                      StartDate: year + '-' + month + '-' + day
                    });
                  }}
                  initialValue={
                    props.policy.Coverage.ActivationTrigger?.StartDate &&
                    new Date(props.policy.Coverage.ActivationTrigger?.StartDate).getTime() > 0
                      ? [day, month, year]
                      : undefined
                  }
                  error={
                    !inputChanged &&
                    props.validationError?.SubField === 'StartDate' &&
                    props.validationError?.Message
                  }
                />
              )}
            </FormColumn>
          </FormRow>
          {props.policy.Coverage.ActivationTrigger.Source == ActivationSource.TrackingNumber && (
            <RequirementDescription style={{ paddingBottom: '10px' }}>
              We will track your package and automatically activate your policy once your package
              has arrived.
            </RequirementDescription>
          )}
          {props.policy.Coverage.ActivationTrigger.Source == ActivationSource.SetStartDate && (
            <RequirementDescription style={{ paddingBottom: '10px' }}>
              We will automatically start your policy on the date you select.
            </RequirementDescription>
          )}
          {props.policy.Coverage.ActivationTrigger.Source == ActivationSource.ManuallyActivate && (
            <RequirementDescription style={{ paddingBottom: '10px' }}>
              You will be able to manually activate your policy on your dashboard at any time.
            </RequirementDescription>
          )}
        </PolicyOptionBox>
      )}
      <div style={{ display: 'flex', gap: '24px' }}>
        <CoveragePageSection title={showAltText ? 'Estimated Price' : 'Your Price'}>
          {!price && loading && <Spinner size={48} color="#cccccc" />}
          {price && (
            <div style={{ display: 'block', gap: '0px' }}>
              <CoveragePrice
                loading={loading}
                price={price}
                displayCadence={props.priceDisplayCadence}
                cadence={props.policy.PaymentCadence}
              />
              {props.policy.Type === PolicyType.chubbJewelry && underwriter != '' && (
                <div style={{ fontSize: '0.75em', color: '#999999', paddingTop: '-10px' }}>
                  {'Insurance Underwritten By: ' +
                    `${underwriter == 'FEDERAL' ? 'Federal Insurance Co' : underwriter}`}
                </div>
              )}
              {props.policy.Type === PolicyType.bike && (
                <div style={{ fontSize: '0.75em', color: '#999999', paddingTop: '-10px' }}>
                  Insurance described is offered by Oyster Insurance Agency, LLC (California license
                  no. 6006158). Insurance is underwritten and provided by Markel Group Inc.
                </div>
              )}
              {showAltText && (
                <div style={{ fontSize: '0.75em', color: '#999999', paddingTop: '-10px' }}>
                  There are credits you may qualify for to improve this price.If you'd like to learn
                  more, continuing does not commit you to finalizing your insurance purchase.
                </div>
              )}
            </div>
          )}
        </CoveragePageSection>
        <CoveragePageSection title="Deductible">
          <div style={{ display: 'block', gap: '0px' }}>
            <UnstyledSelect
              style={{
                fontWeight: 600,
                fontSize: '2em',
                fontFamily: "'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif"
              }}
              disabled={loading}
              selected={props.policy.Coverage.Deductible.Value}
              options={props.policy.Coverage.Deductible.AllowedValues.map((v) => ({
                value: v
              }))}
              onChange={(newValue) => onUpdateDeductible(newValue)}
            />
            {props.policy.Type != PolicyType.chubbJewelry && (
              <div style={{ fontSize: '0.75em', color: '#999999' }}>
                This is the maximum amount you'll pay out-of-pocket in the event of a claim.
              </div>
            )}
          </div>
        </CoveragePageSection>
      </div>
      <div style={{ padding: '18px 0px 0px 0px' }}>
        <ButtonContainer center>
          {props.onBack && (
            <Button leftIcon={<IoArrowBack />} onClick={props.onBack}>
              Back
            </Button>
          )}
          <Button
            icon={<IoArrowForward />}
            onClick={props.onNext}
            loading={props.loading}
            disabled={props.loading || loading}
            primary
          >
            {props.addButtonText || 'Add policy to cart'}
          </Button>
        </ButtonContainer>
      </div>
      <div style={{ padding: '10px 0px' }} />
    </>
  );

  return (
    <>
      {!price && loading && props.showLoading && (
        <PageSection>
          <h1>Verifying your details</h1>
          <p>This may take a few seconds, please do not refresh or navigate away from this page.</p>
          <PageSection noBorder noPadding centered>
            <Spinner size={48} color="#cccccc" />
          </PageSection>
          <div style={{ padding: '10px 0px' }} />
        </PageSection>
      )}
      {(price || !props.showLoading) && !props.isWidget && (
        <PageSection>{coveragePage}</PageSection>
      )}
      {(price || !props.showLoading) && props.isWidget && <>{coveragePage}</>}
    </>
  );
};
