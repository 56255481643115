import React from 'react';

import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ErrorDisplay, TextInput, TextInputComponent } from '@oysterjs/ui/Form/text';
import {
  AssetAccessory,
  DeepPartial,
  Price,
  PublicMerchantRentalConfiguration,
  RentalAsset,
  RentalBooking,
  RentalWaiver,
  ValidationError,
  WaiverState
} from '@oysterjs/types';
import {
  addRentalAssetSerialNumber,
  createRentalBooking,
  createRentalWaiver,
  getMerchantRentalAssets,
  getRentalBooking,
  getWaiverPricing,
  updateRentalBooking,
  updateRentalWaiver
} from '@oysterjs/core/api/merchant';
import { SearchableSelect, Select } from '@oysterjs/ui/Form/select';
import { LoadableContainer } from '@oysterjs/ui/Loadable';
import { Spinner } from '@oysterjs/ui/Spinner';
import { ErrorType, WrappedError } from '@oysterjs/core/errors';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { Banner } from '@oysterjs/ui/Banner';
import { Switch } from '@oysterjs/ui/Form/switch';
import config from '@oysterjs/core/config';
import { LabeledCheckbox } from '@oysterjs/partners/pages/personalization/forms';

const generateBookingReference = () => `B${Math.floor(Math.random() * 10000000000)}`;
const minimumRentalDuration = (config: PublicMerchantRentalConfiguration) =>
  config.AutoRenewWaiver ? 7 : 1;
const minimumRentalDurationDays = (config: PublicMerchantRentalConfiguration) => {
  const duration = minimumRentalDuration(config);
  if (duration === 1) {
    return '1 day';
  } else {
    return `${duration} days`;
  }
};

export const RentalApplication = (props: {
  integrationId: string;
  bookingId?: string;
  rentalConfig: PublicMerchantRentalConfiguration;
}) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours()
  );
  const excludableAssetThreshold = 0.15;

  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);

  const [error, setError] = React.useState<string>();
  const [validationError, setValidationError] = React.useState<ValidationError>();
  const [serialNumberErrors, setSerialNumberErrors] = React.useState<(boolean | undefined)[]>([]);

  const [booking, setBooking] = React.useState<DeepPartial<RentalBooking>>({
    Details: {
      BookingReference: generateBookingReference(),
      AutoRenew: props.rentalConfig.AutoRenewWaiver
    },
    StartTime: today,
    EndTime: new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + minimumRentalDuration(props.rentalConfig),
      today.getHours()
    )
  });

  const [waiver, setWaiver] = React.useState<RentalWaiver>();

  // Indicates that the customer opted in to the rental theft/damage waiver. This is only
  // used if this is the unified signature flow, since otherwise the customer would be
  // filling out this form for the purpose of opting in.
  const [waiverOptedIn, setWaiverOptedIn] = React.useState<boolean>(
    !props.rentalConfig.UnifiedWaiverFlowEnabled
  );
  const [waiverPremium, setWaiverPremium] = React.useState<Price>({ Amount: 10, Currency: 'usd' });

  const [initialDate, setInitialDate] = React.useState<Date>();
  const [durationDays, setDurationDays] = React.useState<string>(
    minimumRentalDuration(props.rentalConfig).toString()
  );

  const [availableAssets, setAvailableAssets] = React.useState<RentalAsset[]>([]);
  const [selectedAssets, setSelectedAssets] = React.useState<RentalAsset[]>([]);
  const [excludedAssetAccessories, setExcludedAssetAccessories] = React.useState<
    Map<number, AssetAccessory[]>
  >(new Map());

  // Create a list of unique asset names
  const assetNames = Object.keys(
    availableAssets.map((a) => a.Name).reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
  );

  // For each unique asset name, create a list of all the serial numbers and associated asset IDs
  const assetSerialNumbers = Object.fromEntries(
    assetNames.map((name) => [
      name,
      availableAssets
        .filter((a) => a.Name === name)
        .map((a) => ({ serialNumber: a.Details.SerialNumber, assetId: a.ID }))
        .filter((s) => !!s)
    ])
  );

  const fetchData = async () => {
    setInitialLoading(true);

    try {
      // Fetch assets
      const assets = await getMerchantRentalAssets(props.integrationId);
      setAvailableAssets(assets || []);

      if (props.bookingId) {
        const { Booking, Waiver } = await getRentalBooking(props.bookingId);
        if (Booking) {
          setBooking(Booking);

          const end = new Date(Booking.EndTime || '');
          const start = new Date(Booking.StartTime || '');

          setInitialDate(start);
          setDurationDays(
            Math.max(
              (end.valueOf() - start.valueOf()) / 86400000,
              minimumRentalDuration(props.rentalConfig)
            ).toString()
          );
          setSelectedAssets(Booking.Details.Assets || []);
          setInitialExcludedAccessories(Booking);
        }
        if (Waiver) {
          setWaiver(Waiver);
        }
      } else {
        setInitialDate(today);
        setDurationDays(minimumRentalDuration(props.rentalConfig).toString());
        setSelectedAssets([assets[0]]);
      }
    } finally {
      setInitialLoading(false);
    }
  };

  const setInitialExcludedAccessories = (booking: RentalBooking) => {
    setExcludedAssetAccessories((prev) => {
      const excludedAccessoriesMap = new Map(prev);
      // Traverse all available accessories for booking assets
      const addedAccessories = [...(booking.Details.Accessories || []).map((a) => a.Name)];
      let searchIndex = 0;
      booking.Details.Assets?.forEach((asset, index) => {
        (asset.Details.AvailableAccessories || []).forEach((acc) => {
          if (!addedAccessories.includes(acc.Name, searchIndex)) {
            excludedAccessoriesMap.set(index, [...(excludedAccessoriesMap.get(index) || []), acc]);
          } else {
            searchIndex++;
          }
        });
      });

      return excludedAccessoriesMap;
    });
  };

  const updateWaiverPremium = async () => {
    const [updatedBooking, updatedWaiver] = getUpdatedBookingAndWaiver();
    const calculatedWaiver = await getWaiverPricing(
      updatedBooking,
      updatedWaiver,
      props.integrationId
    );
    setWaiverPremium({
      Amount: calculatedWaiver.Details.Premium.Total,
      Currency: calculatedWaiver.Details.Premium.Currency
    });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    updateWaiverPremium();
  }, [booking.StartTime, booking.EndTime, booking.MerchantID, selectedAssets]);

  // Update excluded accessories list size upon selected asset changes
  React.useEffect(() => {
    while (excludedAssetAccessories.size < selectedAssets.length) {
      excludedAssetAccessories.set(excludedAssetAccessories.size, []);
    }
    while (excludedAssetAccessories.size > selectedAssets.length) {
      excludedAssetAccessories.delete(excludedAssetAccessories.size - 1);
    }
  }, [selectedAssets]);

  //================================================================
  // BOOKING AND WAIVER LOGIC
  //================================================================

  const onChangeBooking = (
    block: (booking?: DeepPartial<RentalBooking>) => DeepPartial<RentalBooking>
  ) => {
    setValidationError(undefined);
    setBooking({ ...block(booking) });
  };

  const onBookingDateUpdate = (e?: React.ChangeEvent<HTMLInputElement>, duration?: number) => {
    let err: ValidationError | undefined = undefined;
    let localDate = new Date(booking.StartTime as Date);

    if (e?.currentTarget.value) {
      const value = e.currentTarget.value;
      localDate = new Date(value);

      if (isNaN(localDate.getTime())) {
        err = { Field: 'BookingDate', Message: 'Please enter a valid date' };
      } else if (localDate.getTime() < today.getTime()) {
        err = { Field: 'BookingDate', Message: 'Booking date cannot be in the past' };
      }
    }

    if (!isNaN(localDate.getTime())) {
      const d = duration || parseInt(durationDays || '0');
      const startTime = new Date(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes()
      );
      const endTime = new Date(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate() + d,
        localDate.getHours(),
        localDate.getMinutes()
      );

      onChangeBooking((prev) => ({
        ...prev,
        StartTime: startTime.toISOString(),
        EndTime: endTime.toISOString()
      }));
    }

    setValidationError(err);
  };

  const onUpdateBookingDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const stringValue = e.currentTarget.value.trim();
    if (!stringValue) {
      setDurationDays('');
      onChangeBooking((prev) => ({ ...prev, EndTime: undefined }));
      setValidationError({ Field: 'BookingDuration', Message: 'Duration cannot be empty' });
    }

    const value = parseInt(stringValue);
    if (!isNaN(value) && value > 0) {
      setDurationDays(value.toString());
      onBookingDateUpdate(undefined, value);
      setValidationError(undefined);
    }

    if (!isNaN(value) && value <= 0) {
      setDurationDays(stringValue);
      onChangeBooking((prev) => ({ ...prev, EndTime: undefined }));
      setValidationError({
        Field: 'BookingDuration',
        Message: `Duration must be at least ${minimumRentalDurationDays(props.rentalConfig)}`
      });
    }
  };

  const localDateFormat = (d: Date) =>
    `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
      .getDate()
      .toString()
      .padStart(2, '0')}T${d.getHours().toString().padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;

  //================================================================
  // SERIAL NUMBERS LOGIC
  //================================================================

  const setSerialNumberError = (index: number) =>
    setSerialNumberErrors((errors) => {
      const newErrors = [...errors];
      newErrors[index] = true;
      return newErrors;
    });

  const clearSerialNumberError = (index: number) =>
    setSerialNumberErrors((errors) => {
      const newErrors = [...errors];
      newErrors[index] = undefined;
      return newErrors;
    });

  const selectSerialNumber = (index: number, serialNumber: string, assets: RentalAsset[]) => {
    if (!serialNumber) {
      setSerialNumberError(index);
      return;
    }

    const selectedAsset = assets.find((ast) => ast.Details.SerialNumber === serialNumber);

    if (!selectedAsset) {
      setSerialNumberError(index);
      return;
    }

    setSelectedAssets((prev) => {
      const newAssets = [...prev];
      newAssets[index] = selectedAsset;
      return newAssets;
    });
    clearSerialNumberError(index);
  };

  const handleSelectSerialNumber = (index: number) => (serialNumber: string) =>
    selectSerialNumber(index, serialNumber, availableAssets);

  const handleAddSerialNumber = (index: number) => async (serialNumber: string) => {
    if (loading) {
      return;
    }

    setLoading(true);

    const assetId = selectedAssets[index]?.ID;
    if (!assetId) {
      throw new Error('Asset not found');
    }

    try {
      await addRentalAssetSerialNumber(assetId, serialNumber, props.integrationId);
      const assets = await getMerchantRentalAssets(props.integrationId);

      selectSerialNumber(index, serialNumber, assets);
      setAvailableAssets(assets);
      setError(undefined);
    } catch (e) {
      const err = WrappedError.asWrappedError(e);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  //================================================================
  // CREATE/UPDATE WAIVER LOGIC
  //================================================================

  const getUpdatedBookingAndWaiver = (): [RentalBooking, RentalWaiver] => [
    {
      ...booking,
      Details: {
        ...booking.Details,
        Assets: selectedAssets,
        Accessories: selectedAssets.flatMap((a, index) =>
          (a.Details.AvailableAccessories || []).filter(
            // Filter out accessories that are in exclusion list
            (a) =>
              !excludedAssetAccessories.get(index)?.find((excluded) => excluded.Name === a.Name)
          )
        )
      }
    } as unknown as RentalBooking,
    {
      ...waiver,
      State: WaiverState.pending,
      Details: {
        ...waiver?.Details,
        Assets: selectedAssets.map((a, index) => ({
          Asset: a,
          Accessories: (a.Details.AvailableAccessories || []).filter(
            // Filter out accessories that are in exclusion list
            (a) =>
              !excludedAssetAccessories.get(index)?.find((excluded) => excluded.Name === a.Name)
          )
        }))
      }
    } as unknown as RentalWaiver
  ];

  const onCreateOrUpdateWaiver = () => {
    let apiFunc = (booking: RentalBooking, waiver: RentalWaiver) =>
      !props.rentalConfig.UnifiedWaiverFlowEnabled || waiverOptedIn
        ? createRentalWaiver(booking, waiver, false, props.integrationId)
        : createRentalBooking(booking, props.integrationId);

    if (props.bookingId) {
      apiFunc = (booking: RentalBooking, waiver: RentalWaiver) =>
        !props.rentalConfig.UnifiedWaiverFlowEnabled || waiverOptedIn
          ? updateRentalWaiver(booking, waiver, false, props.integrationId)
          : updateRentalBooking(booking, props.integrationId);
    }

    // Check that selected assets have a serial number and that the
    // serial number matches an available asset.
    let hasSerialNumberError = false;
    for (let i = 0; i < selectedAssets.length; i++) {
      const serialNumber = selectedAssets[i].Details.SerialNumber;
      const asset = availableAssets.find((ast) => ast.Details.SerialNumber === serialNumber);

      if (!serialNumber || !asset) {
        setSerialNumberError(i);
        hasSerialNumberError = true;
      }
    }

    if (hasSerialNumberError || validationError) {
      return;
    }

    setError(undefined);
    setSubmitLoading(true);

    const [updatedBooking, updatedWaiver] = getUpdatedBookingAndWaiver();
    apiFunc(updatedBooking as unknown as RentalBooking, updatedWaiver as unknown as RentalWaiver)
      .then((res) => {
        const [, type, id, product] = history.location.pathname.split('/');
        history.replace(`/${type}/${id}/${product}/${res.UpdatedBooking.ID}`);
        if (res.UpdatedWaiver) {
          history.push(`/rental/waiver/${res.UpdatedWaiver.ID}/confirm`);
        } else {
          history.push(`/rental/booking/${res.UpdatedBooking.ID}/confirm`);
        }
      })
      .catch((e) => {
        const err = WrappedError.asWrappedError(e);
        if (err.type() === ErrorType.validationError) {
          setError(undefined);
          setValidationError(err.getValidationError());
        } else {
          setValidationError(undefined);
          setError(err.message);
        }
      })
      .finally(() => setSubmitLoading(false));
  };

  if (initialLoading) {
    return (
      <LoadableContainer>
        <Spinner color="#333333" />
      </LoadableContainer>
    );
  }

  return (
    <FormContainer onSubmit={(e) => e.preventDefault()}>
      <FormRowHeader
        title="Your Booking"
        description={`Tell us the start date and duration of your booking. The minimum duration is ${minimumRentalDurationDays(
          props.rentalConfig
        )}.`}
      />
      <FormRow breakMobile>
        <FormColumn title="Rental start date">
          <TextInputComponent
            type="datetime-local"
            defaultValue={localDateFormat(initialDate || today)}
            min={localDateFormat(today)}
            onChange={onBookingDateUpdate}
            error={validationError?.Field === 'BookingDate'}
          />
          {validationError?.Field === 'BookingDate' && (
            <ErrorDisplay>{validationError.Message}</ErrorDisplay>
          )}
        </FormColumn>
        <FormColumn title="Rental duration (days)">
          <TextInputComponent
            type="number"
            min={minimumRentalDuration(props.rentalConfig)}
            value={durationDays}
            inputMode="numeric"
            style={{ maxWidth: '150px' }}
            onChange={onUpdateBookingDuration}
            error={validationError?.Field === 'BookingDuration'}
          />
          {validationError?.Field === 'BookingDuration' && (
            <ErrorDisplay>{validationError.Message}</ErrorDisplay>
          )}
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Rental Assets"
        description="List each asset you're renting, including its serial or identification number."
      />
      {selectedAssets.map((asset, index) => (
        <div key={asset.ID}>
          <FormRow breakMobile>
            <FormColumn title="Asset">
              <Select
                options={assetNames.map((name) => ({ value: name }))}
                value={asset.Name}
                onChange={(value) => {
                  const selectedAsset = availableAssets.find((ast) => ast.Name === value);
                  if (!selectedAsset) {
                    throw new Error('Selected asset not found');
                  }

                  setSelectedAssets((prev) => {
                    const newAssets = [...prev];
                    newAssets[index] = selectedAsset;
                    return newAssets;
                  });
                }}
              />
            </FormColumn>
            <FormColumn title="Serial number">
              <SearchableSelect
                options={
                  assetSerialNumbers[asset.Name]?.map((a) => a.serialNumber).filter((s) => !!s) ||
                  []
                }
                initialValue={asset.Details.SerialNumber}
                error={
                  serialNumberErrors[index]
                    ? 'Please choose a serial number or enter a new one and click "add"'
                    : undefined
                }
                onChange={handleSelectSerialNumber(index)}
                onSelectOption={handleSelectSerialNumber(index)}
                onAddOption={handleAddSerialNumber(index)}
              />
            </FormColumn>
          </FormRow>
          {(asset.Details.AvailableAccessories || [])
            .filter((a) => a.Value > excludableAssetThreshold * asset.Value)
            .map((accessory) => {
              return (
                <FormRow singleItem style={{ margin: '16px' }}>
                  <FormColumn>
                    <LabeledCheckbox
                      label={`Add ${accessory.Name.toLocaleLowerCase()}`}
                      checked={
                        !excludedAssetAccessories.get(index)?.find((a) => a.Name === accessory.Name)
                      }
                      onChange={() => {
                        setExcludedAssetAccessories((prev) => {
                          let updatedExclusions: AssetAccessory[] = [];
                          if (
                            excludedAssetAccessories
                              .get(index)
                              ?.find((a) => a.Name === accessory.Name)
                          ) {
                            // If asset is already excluded, remove the exclusion
                            updatedExclusions =
                              prev.get(index)?.filter((a) => a.Name !== accessory.Name) || [];
                          } else {
                            // If asset is not excluded, add the exclusion
                            updatedExclusions = [...(prev.get(index) || []), accessory];
                          }

                          const updatedMap = new Map(prev);
                          updatedMap.set(index, updatedExclusions);
                          return updatedMap;
                        });
                      }}
                    />
                  </FormColumn>
                </FormRow>
              );
            })}
        </div>
      ))}
      <ButtonContainer>
        <Button onClick={() => setSelectedAssets((prev) => [...prev, availableAssets[0]])}>
          Add another asset
        </Button>
        {selectedAssets.length > 1 && (
          <Button onClick={() => setSelectedAssets((prev) => [...prev].slice(0, -1))}>
            Remove previous asset
          </Button>
        )}
      </ButtonContainer>
      <FormRowHeader
        title="Your Information"
        description="Enter the primary renter's personal information here."
      />
      <FormRow>
        <FormColumn title="First name">
          <TextInput
            error={validationError?.Field === 'FirstName' && validationError?.Message}
            value={booking?.Details?.Insured?.FirstName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeBooking((prev) => ({
                ...prev,
                Details: {
                  ...prev?.Details,
                  Insured: { ...prev?.Details?.Insured, FirstName: value }
                }
              }));
            }}
            autoComplete="given-name"
          />
        </FormColumn>
        <FormColumn title="Last name">
          <TextInput
            error={validationError?.Field === 'LastName' && validationError?.Message}
            value={booking?.Details?.Insured?.LastName}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeBooking((prev) => ({
                ...prev,
                Details: {
                  ...prev?.Details,
                  Insured: { ...prev?.Details?.Insured, LastName: value }
                }
              }));
            }}
            autoComplete="family-name"
          />
        </FormColumn>
      </FormRow>
      <FormRow breakMobile>
        <FormColumn title="Email address">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={validationError?.Field === 'Email' && validationError?.Message}
            value={booking?.Details?.Insured?.Email}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeBooking((prev) => ({
                ...prev,
                Details: {
                  ...prev?.Details,
                  Insured: { ...prev?.Details?.Insured, Email: value }
                }
              }));
            }}
            inputMode="email"
            autoComplete="email"
          />
        </FormColumn>
        <FormColumn title="Phone number">
          <TextInput
            style={{ maxWidth: '100%' }}
            error={validationError?.Field === 'Phone' && validationError?.Message}
            value={booking?.Details?.Insured?.Phone}
            onChange={(e) => {
              const value = e.currentTarget.value;
              onChangeBooking((prev) => ({
                ...prev,
                Details: {
                  ...prev?.Details,
                  Insured: { ...prev?.Details?.Insured, Phone: value }
                }
              }));
            }}
            inputMode="tel"
            autoComplete="tel"
          />
        </FormColumn>
      </FormRow>
      {props.rentalConfig.UnifiedWaiverFlowEnabled && (
        <RentalAgreement integrationId={props.integrationId} />
      )}
      {props.rentalConfig.UnifiedWaiverFlowEnabled && (
        <RentalWaiverPrompt
          totalLiability={{
            Amount: selectedAssets
              .flatMap((a) => [
                a.Value,
                ...(a.Details.AvailableAccessories || []).map((aa) => aa.Value)
              ])
              .reduce((a, c) => a + c, 0),
            Currency: 'usd'
          }}
          waiverPremium={waiverPremium}
          waiverOptedIn={waiverOptedIn}
          setWaiverOptedIn={setWaiverOptedIn}
        />
      )}
      <FormRow>
        <ButtonContainer center>
          <Button
            primary
            icon={<IoArrowForward />}
            loading={submitLoading}
            onClick={onCreateOrUpdateWaiver}
          >
            {!props.rentalConfig.UnifiedWaiverFlowEnabled
              ? 'Continue'
              : waiverOptedIn
              ? 'Continue to sign and pay'
              : 'Continue to sign'}
          </Button>
        </ButtonContainer>
      </FormRow>

      {error && <ErrorDisplay>{error}</ErrorDisplay>}
    </FormContainer>
  );
};

const RentalAgreement = (props: { integrationId: string }) => {
  const ref = React.useRef<HTMLIFrameElement>(null);

  const url =
    config().backendBaseUrl.statics +
    `/merchant/rental/waiverdocuments?IntegrationID=${props.integrationId}`;
  const embeddedUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
    url
  )}`;

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current) {
        ref.current.src = embeddedUrl;
      }
    }, 3000);

    if (ref.current) {
      ref.current.addEventListener('load', () => {
        clearInterval(interval);
      });
    }

    return () => clearInterval(interval);
  }, [ref.current]);

  return (
    <>
      <FormRowHeader
        title="Rental Agreement"
        description="Read each document carefully and ensure you understand them. You'll be asked to sign confirming your agreement with these terms."
      />
      <FormRow>
        <iframe ref={ref} src={embeddedUrl} width="100%" height={500} />
      </FormRow>
    </>
  );
};

const RentalWaiverPrompt = (props: {
  totalLiability: Price;
  waiverPremium: Price;
  waiverOptedIn: boolean;
  setWaiverOptedIn: (value: boolean) => void;
}) => (
  <>
    <Banner
      style={{ background: props.waiverOptedIn ? '#E3f7f3' : undefined }}
      title={`Waive up to ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'usd',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(props.totalLiability.Amount)} in liability in the event of theft or damage.`}
      bannerAction={
        <Switch
          enabled={props.waiverOptedIn}
          onChange={() => props.setWaiverOptedIn(!props.waiverOptedIn)}
        />
      }
      description={
        props.waiverOptedIn ? (
          <>
            <b>Your rental will be protected.</b> After you sign and pay{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'usd',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(props.waiverPremium.Amount)}
            , you won't be liable for theft or damage (terms apply).
          </>
        ) : (
          <>
            <b>Your rental is not protected.</b> You will be liable for the full value of this
            rental in the event of theft or damage. However, you can choose to waive any liability
            for damage and theft during your rental for an additional{' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'usd',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(props.waiverPremium.Amount)}{' '}
            (terms apply).
          </>
        )
      }
    />
  </>
);
